import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useBlacklistSenderIdMutation, useBlacklistReceiverMutation, useBlacklistContentIDMutation, useBlacklistKeywordMutation, useGetRegisteredAccountNameMutation } from "services/post";
import authorsTableData from "layouts/tables/data/authorsTableData";
import templateMappingData from "layouts/dlt/data/templateMappingData";

import senderIdData from "./data/senderIdData";
import receiverIdData from "./data/receiverData";
import contentIdData from "./data/contentIdData";
import keywordIddata from "./data/keywordData";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox, Icon, Input, TextField } from "@mui/material";
import { useGetRegisteredUserListMutation } from "services/post";
import { useGetRegisteredAccountsListMutation } from "services/post";

function Blacklisting() {
  const [postData, setPostData] = useState("")
  const { columns: sColumns, rows: sRows } = senderIdData(postData);
  const { columns: rColumns, rows: rRows } = receiverIdData(postData);
  const { columns: cColumns, rows: cRows } = contentIdData(postData);
  const { columns: kColumns, rows: kRows } = keywordIddata(postData);
  const [message, setMessage] = useState("")
  const [value, setValue] = useState("sender_id");
  // const [age, setAge] = useState("");
  const [senderIds, setSenderIds] = useState([]);
  const [peIds, setPeIds] = useState("");
  const [file, setFile] = useState(null);
  const [blacklistSenderIdRegistration] = useBlacklistSenderIdMutation();
  const [blacklistReceiverRegistration] = useBlacklistReceiverMutation();
  const [blacklistContentIdRegistration] = useBlacklistContentIDMutation();
  const [blacklistKeywordRegistration] = useBlacklistKeywordMutation();
  const [registeredAccountsList] = useGetRegisteredAccountsListMutation();
  const [accountName] = useGetRegisteredAccountNameMutation();

  // const [mapTemplateSenderId] = useMapTemplateSenderIdMutation();
  const [error, setError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const fileInputRef = useRef(null);
  const [account, setAccount] = useState([])
  const [accounts, setAccounts] = useState("")
  const [userChainDetail, setUserChainDetail] = useState("")
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState([])
  const [selectedFileName, setSelectedFileName] = useState([]);
  const [exportUser, setExportUser] = useState('')





  useEffect(() => {
    localStorage.setItem("blacklistNav", value)
    setPostData("")
    registeredAccountsList("")
      .then((success) => {
        console.log('HIT BEFORE:::::::::', success.data.get_registered_accounts
        );
        if (success.data.statusCode === 200) {
          success.data.get_registered_accounts.map((key) => {
            setUsers((prevUsers) => [...prevUsers, key.parent]);
          });
          setUserData(success.data.get_registered_accounts);
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");

      });
  }, [value])

  const handleChange = (event, newValue) => {
    setFile(null);
    setPeIds("")
    setError("");
    setSenderIds("")
    setUserChainDetail("")
    setAccount("");
    setExportUser("")
    setValue(newValue);
    localStorage.setItem("blacklistNav", newValue)
  };

  const handleSenderIdsChange = (event) => {
    const selectedSenderId = event.target.value;
    setSenderIds(event.target.value);
    setPostData(selectedSenderId)
    if (selectedSenderId !== "") {
      accountName(selectedSenderId)
        .then((success) => {
          if (success.data.statusCode === 200) {
            success.data.get_registered_accounts_name.map((key) => {
              setAccount((prevUsers) => [...prevUsers, key.system_id]);
            });
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");

        });
    }
    else {
      setAccount([])
    }

  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    const files = Array.from(event.target.files);
    setSelectedFileName(files.map(file => file.name));
  };

  const handleACcountChange = (event) => {
    setAccount(event.target.value)
  }

  const handleUserChainChange = (event) => {
    setUserChainDetail(event.target.value)
  }

  const handleSubmit = async () => {
    if (!senderIds) {
      setError("Please select a Sender ID.");
      return;
    }

    if (!file) {
      setError("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("account", accounts);
    formData.append("user_chain", senderIds);

    if (value === "sender_id") {
      blacklistSenderIdRegistration(formData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setFile(null);
            setAccount("");
            setSenderIds("");
            setSelectedFileName("")
            setUserChainDetail("");
            // setAge("")
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    } else if (value === "receiver") {
      blacklistReceiverRegistration(formData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setFile(null);
            setAccount("");
            setSenderIds("");
            setUserChainDetail("");
            // setAge("")
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
    else if (value === "contentid") {
      blacklistContentIdRegistration(formData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setFile(null);
            setAccount("");
            setSenderIds("");
            setUserChainDetail("");
            // setAge("")
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
    else if (value === "spam") {
      blacklistKeywordRegistration(formData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setFile(null);
            setAccount("");
            setSenderIds("");
            setUserChainDetail("");
            // setAge("")
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
  };

  const userChain = [...new Set(sRows.map((row) => row.user_chain?.props?.children).filter(Boolean))];
  // Extract sender IDs from projectsTableData
  // const senderIDs = [...new Set(pRows.map((row) => row.sender_id))];
  const senderIDList = sRows.map((row) => {
    const children = row.id.props.children;
    return children;
  });


  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Registration"
      content={message}
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleDownloadSample = () => {
    if (value === 'sender_id') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=sender_id_blacklisting.xlsx`;
      link.download = 'sample-file.ext';
      link.click();
    }
    if (value === 'receiver') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=receiver.xlsx`;
      link.download = 'sample-file.ext';
      link.click();
    }
    if (value === 'contentid') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=contentid_blacklist.xlsx`;
      link.download = 'sample-file.ext';
      link.click();
    }
    if (value === 'spam') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=keyword_blacklist.xlsx`;
      link.download = 'sample-file.ext';
      link.click();
    }
  };



  const exportValue = async () => {
    const encodedData = encodeURIComponent(exportUser);
    let encodedValue = encodeURIComponent(value);
    let fileName;

    switch (value) {
      case 'sender_id':
        fileName = 'sender.csv';
        break;
      case 'receiver':
        fileName = 'receiver.csv';
        break;
      case 'contentid':
        fileName = 'contentId.csv';
        break;
      case 'spam':
        fileName = 'spam.csv';
        break;
      default:
        console.error('Unknown value:', value);
        return;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/export_blacklisting/${encodedValue}?user_chain=${encodedData}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          // Handle "No records found" error
          alert('No records found.');
        } else {
          // Handle other possible errors
          alert(`Error: ${response.statusText}`);
        }
        return;
      }

      // If the request is successful, trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred while exporting the data.');
    }
  };




  return (
    <DashboardLayout>
      {renderSuccessSB}
      <DashboardNavbar />
      <Box pt={3} pb={3}>
        <TabContext value={value} sx={{ backgroundColor: "white" }}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Sender ID" value="sender_id" />
              <Tab label="Receiver" value="receiver" />
              <Tab label="Content ID" value="contentid" />
              <Tab label="Keyword" value="spam" />

            </TabList>
          </Box>

          <TabPanel value="sender_id">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={3} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Sender ID
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration for Blacklisting!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">User Chain</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={senderIds}
                              onChange={handleSenderIdsChange}
                              label="User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={accounts}
                              onChange={(e) => setAccounts(e.target.value)}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "110%", marginLeft: "32px" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Senders Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>

                        <DataTable
                          table={{ columns: sColumns, rows: sRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="receiver">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Receiver
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration for Blacklisting!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">User Chain</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={senderIds}
                              onChange={handleSenderIdsChange}
                              label="User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={account}
                              onChange={handleACcountChange}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "110%", marginLeft: "-9%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Receiver Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser||""}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: rColumns, rows: rRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="contentid">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Content
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration for Blacklisting!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">User Chain</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={senderIds}
                              onChange={handleSenderIdsChange}
                              label="User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={account}
                              onChange={handleACcountChange}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "110%", marginLeft: "-9%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Content Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: cColumns, rows: cRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="spam">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Keyword
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration for Blacklisting!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">User Chain</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={senderIds}
                              onChange={handleSenderIdsChange}
                              label="User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={account}
                              onChange={handleACcountChange}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "110%", marginLeft: "-9%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Keyword Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: kColumns, rows: kRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>

        </TabContext>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Blacklisting;
