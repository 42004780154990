import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Button, LinearProgress, CircularProgress, Icon } from '@mui/material';

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useBulksenderidregMutation, useMapTemplateSenderIdMutation, useGetRegisteredUserNameMutation, useExportSenderIdMutation, useExportTemplateIdMutation } from "services/post";
import templateMappingData from "layouts/dlt/data/templateMappingData";
import projectsTableData from "layouts/dlt/data/projectsTableData";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox } from "@mui/material";


function Tables() {
  const [postData, setPostData] = useState("")
  const [postsData, setPostsData] = useState("")
  const { columns: tColumns, rows: tRows } = templateMappingData(postsData);
  const { columns: pColumns, rows: pRows } = projectsTableData(postData);
  const [value, setValue] = useState("1");
  const [age, setAge] = useState("");
  const [userId, setUserID] = useState("")
  const [senderID, setSenderID] = useState([]);
  const [senderIds, setSenderIds] = useState([]);
  const [peIds, setPeIds] = useState([]);
  const [file, setFile] = useState(null);
  const [bulkSenderIdRegistration] = useBulksenderidregMutation();
  const [mapTemplateSenderId] = useMapTemplateSenderIdMutation();
  const [senderExport] = useExportSenderIdMutation();
  const [templateExport] = useExportTemplateIdMutation();

  const [error, setError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const fileInputRef = useRef(null);
  const [exportUser, setExportUser] = useState('')
  const [getUserName] = useGetRegisteredUserNameMutation()
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState([])
  const [user, setUser] = useState()

  const [selectedFileName, setSelectedFileName] = useState([]);
  const [loading, setLoading] = useState(false); // To manage loading state
  const [uploadProgress, setUploadProgress] = useState(0); // To manage progress state
  const [templateUsers, setTemplateUsers] = useState("")


  const handleChange = (event, newValue) => {
    setFile(null);
    setPeIds("")
    setError("");
    setSenderIds("")
    setAge("")
    setValue(newValue);
  };

  useEffect(() => {
    getUserName()
      .then((success) => {
        if (success.data.statusCode === 200) {
          success.data.get_user_registered.map((key) => {
            setUsers((prevUsers) => [...prevUsers, key.username]);
          });
          setUserData(success.data.get_user_registered)
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");
      });
  }, [])

  // const handleSenderIdsChange = (event) => {

  //   console.log('multiple valuessss', event.target.value);
  //   const selectedSenderId = event.target.value;
  //   setSenderID(selectedSenderId)
  //   const correspondingPeId = pRows.find((row) => row.sender_id.props.children === selectedSenderId)?.peid.props.children;
  //   if (correspondingPeId) {
  //     setPeIds(correspondingPeId);
  //   } else {
  //     setPeIds("");
  //   }
  // };

  const handleSenderIdsChange = (event) => {
    const selectedSenderIds = event.target.value; // Array of selected sender IDs
    setSenderID(selectedSenderIds);
  
    // Create an array of { sender_id, peid } pairs
    const newPeIdPairs = selectedSenderIds.map((selectedId) => {
      const correspondingPeId = pRows.find(
        (row) => row.sender_id.props.children === selectedId
      )?.peid.props.children || "";
  
      return { sender_id: selectedId, peid: correspondingPeId };
    });
  
    setPeIds(newPeIdPairs); // Store the pairs in state
  };
  

  const handleSelectUserChange = (event) => {


    const selectedSenderId = event.target.value;
    setUser(selectedSenderId);
    setAge(selectedSenderId);

    const correspondingUserId = userData.find((row) => row.username === selectedSenderId)?.id;

    const correspondingSenderIds = pRows
      .filter((row) => row.user_id?.props?.children === correspondingUserId.toString())
      .map((row) => row.sender_id?.props?.children);

    if (correspondingSenderIds.length > 0) {
      setSenderIds(correspondingSenderIds);
    } else {
      setSenderIds([]);
    }

  };



  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    const files = Array.from(event.target.files);
    setSelectedFileName(files.map(file => file.name));
  };

  const handleSubmit = async () => {
    if (value === "1") {
      if (!age) {
        setError("Please select a User.");
        return;
      }

      if (!file) {
        setError("Please select a file.");
        return;
      }
      const selectedUser = userData.find((u) => u.username === user);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", selectedUser.id);

      try {
        const result = await bulkSenderIdRegistration(formData).unwrap();
        setSuccessSB(true);
        setPostData(selectedUser.id);
        setSelectedFileName([]);
        setAge('');
        setFile(null);
        setError('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error('Error:', error);

      }
    } else if (value === "2") {
      if (!senderIds) {
        setError("Please select Sender IDs.");
        return;
      }

      if (!peIds) {
        setError("Please select PE IDs.");
        return;
      }
      if (!file) {
        setError("Please select a file.");
        return;
      }

      const selectedUser = userData.find((u) => u.username === user);
      const formData = new FormData();
      formData.append("user_id", selectedUser.id);
      formData.append("sender_ids", JSON.stringify(peIds)); 
      formData.append("file", file);

      try {
        const result = await mapTemplateSenderId(formData).unwrap();
        setSuccessSB(true);
        setPostsData(selectedUser.id);
        setSelectedFileName([]);
        setSuccessSB(true);
        setFile(null);
        setPeIds("")
        setError("");
        setAge("")
        setSenderIds([""])
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error('Error:', error);

      }
    }
  };


  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Registration"
      content="Operation completed successfully"
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const handleDownloadSample = () => {
    if (value === '1') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=sender_id.xlsx`;
      link.download = 'sample-file.ext';
      link.click();
    }
    if (value === '2') {
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=template_id.xlsx`;
      link.download = 'sample-file.ext';
      link.click();
    }
  };

  const exportValue = () => {
    if (value === '1') {
      const selectedUser = userData.find((u) => u.username === exportUser);
      const link = document.createElement('a');
      link.href = `${process.env.REACT_APP_API_BASE_URL}/export_senderid_mapping?user_id=${selectedUser.id}`;
      link.download = 'sender.csv';
      link.click();
    }
    if (value === '2') {
    
      const fileUrl = `${process.env.REACT_APP_API_BASE_URL}/export_template_mapping?user=${templateUsers}`;
    
      fetch(fileUrl, { method: 'HEAD' })
        .then((response) => {
          if (response.ok) {
            // If the file exists, trigger the download
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = 'sender.csv';
            link.click();
          } else {
            // Handle the case where the file does not exist
            console.error('File not found');
            alert('The file you are trying to download does not exist.');
          }
        })
        .catch((error) => {
          console.error('An error occurred while checking the file:', error);
          alert('An error occurred while trying to download the file.');
        });
    }
    

  }


  const templateUser = tRows.map((row) => {
    const children = row.user.props.children;
    return children;
  });
  console.log('senderIDs::', templateUser);
  return (
    <DashboardLayout>
      {renderSuccessSB}
      <DashboardNavbar />
      <Box pt={3} pb={3}>
        <TabContext value={value} sx={{ backgroundColor: "white" }}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Sender ID" value="1" />
              <Tab label="Template Mapping" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={3} ml={5}>
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Sender ID
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Select User</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={age}
                              onChange={handleSelectUserChange}
                              label="Sender ID"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={6}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Registered Via</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value="file"
                              disabled
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="file">File</MenuItem>
                              <MenuItem value="manual">Manual</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1} mt={3}>
                          <Checkbox />
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;I agree to the&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                          >
                            Terms and Conditions
                          </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>



                <Grid container spacing={6} xl={9} xs={12} >
                  <Grid item xs={12} mr={4}>
                    <Card style={{ width: "100%", marginLeft: "9px" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Senders Details
                        </MDTypography>
                      </MDBox>

                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>

                        <DataTable
                          table={{ columns: pColumns, rows: pRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>

          <TabPanel value="2">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={3} ml={5}>
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Template Mapping
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Select User</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={age}
                              onChange={handleSelectUserChange}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="senderIds-label">Sender IDs</InputLabel>
                            <Select
                              labelId="senderIds-label"
                              id="senderIds"
                              value={senderID}
                              onChange={handleSenderIdsChange}
                              label="Sender IDs"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                              multiple
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {senderIds && senderIds.length > 0 ? (
                                senderIds.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Sender IDs Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </MDBox>

                        {/* <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="peIds-label">PE IDs</InputLabel>
                            <Select
                              labelId="peIds-label"
                              id="peIds"
                              value={peIds}
                              disabled
                              label="PE IDs"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={peIds}>{peIds}</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox> */}
                        <MDBox mb={2} mt={3}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="registeredBy-label">Registered By</InputLabel>
                            <Select
                              labelId="registeredBy-label"
                              id="registeredBy"
                              value="file"
                              disabled
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="file">File</MenuItem>
                              <MenuItem value="manual">Manual</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1} mt={3}>
                          <Checkbox />
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;I agree to the&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                          >
                            Terms and Conditions
                          </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid container spacing={6} xl={9} >
                  <Grid item xs={12} mr={4}>
                    <Card style={{ width: "97%", marginLeft: "9px" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Template Mapping Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>

                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={templateUsers}
                              onChange={(e) => setTemplateUsers(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {templateUser?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>

                        <DataTable
                          table={{ columns: tColumns, rows: tRows }}
                          isSorted={false}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>

        </TabContext>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
