

export default {
  labels: [
    "S",
    "M",
    "T",
    "W",
    "T",
    "F",
    "S"
  ],
  datasets: {
    label: "SMS",
    data: [
      128,
      2,
      158410,
      69616,
      199844,
      208739,
      10002
    ]
  },


};
