import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useGetSmscDetailsMutation } from 'services/post';
import MDBox from 'components/MDBox';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Data = (postData) => {
  const [rows, setRows] = useState([]);
  const [smscDetail] = useGetSmscDetailsMutation();
  useEffect(() => {
    smscDetail()
      .unwrap()
      .then((response) => {
        if (response?.statusCode === 200) {
          const fetchedData = response.data.map((item) => ({
            smscId: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['smsc-id']}
              </MDTypography>
            ),
            altCharacterset: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['alt-charset']}
              </MDTypography>
            ),
            transceiverMode: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['transceiver-mode']}
              </MDTypography>
            ),
            host: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.host}
              </MDTypography>
            ),
            port: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.port}
              </MDTypography>
            ),
            username: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['smsc-username']}
              </MDTypography>
            ),
            password: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['smsc-password']}
              </MDTypography>
            ),
            systemType: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['system-type']}
              </MDTypography>
            ),
            sourceTon: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['source-addr-ton']}
              </MDTypography>
            ),
            sourceNpi: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['source-addr-npi']}
              </MDTypography>
            ),
            destTon: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['dest-addr-ton']}
              </MDTypography>
            ),
            destNpi: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item['dest-addr-npi']}
              </MDTypography>
            ),
            throughput: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.throughput}
              </MDTypography>
            ),
            instances: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.instances}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (response.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [smscDetail, postData]);

  return {
    columns: [
      { Header: "SMSC ID", accessor: "smscId", align: "left" },
      { Header: "ALT-Charset", accessor: "altCharacterset", align: "left" },
      { Header: "Transceiver Mode", accessor: "transceiverMode", align: "left" },
      { Header: "Host", accessor: "host", align: "left" },
      { Header: "Port", accessor: "port", align: "left" },
      { Header: "Username", accessor: "username", align: "left" },
      { Header: "Password", accessor: "password", align: "left" },
      { Header: "System Type", accessor: "systemType", align: "left" },
      { Header: "Source Addr-ton", accessor: "sourceTon", align: "left" },
      { Header: "Source Addr-npi", accessor: "sourceNpi", align: "left" },
      { Header: "Dest Addr-ton", accessor: "destTon", align: "left" },
      { Header: "Dest Addr-npi", accessor: "destNpi", align: "left" },
      { Header: "Throughput", accessor: "throughput", align: "left" },
      { Header: "Instances", accessor: "instances", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },





    ],
    rows: rows,
  };
};

export default Data;
