import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
import masterCardLogo from "assets/images/logos/mastercard.png";
import visaLogo from "assets/images/logos/visa.png";
import { useAddWalletAmountMutation } from "services/post";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import { toast } from 'react-toastify';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useGetRegisteredUserNameMutation } from "services/post";

function PaymentMethod() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [add_wallet_amount] = useAddWalletAmountMutation();
  const [getUserName] = useGetRegisteredUserNameMutation()
  const [payment, setPayment] = useState('false')
  const [amount, setAmount] = useState("")
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [userData, setUserData] = useState([])


  useEffect(() => {
    getUserName()
      .then((success) => {
        if (success.data.statusCode === 200) {
          success.data.get_user_registered.map((key) => {
            setUsers((prevUsers) => [...prevUsers, key.username]);
          });
          setUserData(success.data.get_user_registered)
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");
      });
  }, [])

  console.log("USER LIST", users);
  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Amount"
      content="Hello, your amount has been submitted successfully"
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );


  const handleChange = (e) => {
    setPayment(e.target.value)
  }

  const amountSubmit = (e) => {
    setAmount(e.target.value)

  }

  const paymentDone = () => {
    // Find the user by the selected username
    const selectedUser = userData.find((u) => u.username === user);
    console.log('electedUser.user_chain', selectedUser.user_chain);
    if (!selectedUser) {
      // Handle the case where the user was not found
      console.error("User not found");
      return;
    }

    // Create the postData with the selected user's id and user_chain
    const postData = {
      user_id: selectedUser.id,
      amount: amount,
      user_chain: selectedUser.user_chain,
    };

    add_wallet_amount(postData)
      .then((success) => {
        console.log('Response:', success);
        if (success.data.statusCode === 200) {
          setSuccessSB(true);
          setPayment('false')
          setAmount("")
          setUser("")

        } else if (success?.error?.data?.statusCode > 300) {
          console.error("Error:", success?.error?.data?.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleUsersChange = (event) => {
    setUser(event.target.value);
    setPayment('1')
  }


  return (
    <Card id="delete-account">
      {renderSuccessSB}
      <MDBox pt={2} pb={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Payment Method
        </MDTypography>

        {payment === 'false' &&
          <MDButton variant="gradient" value='true' color="dark" onClick={handleChange}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add wallet amount
          </MDButton>

        }
        {payment === 'true' &&
          <MDBox>
            <MDBox mb={2} width="100%" sx={{
              height: '10px',
            }}
            >
              <FormControl variant="standard" fullWidth>
                <InputLabel id="senderIds-label">Select User</InputLabel>
                <Select
                  labelId="senderIds-label"
                  id="senderIds"
                  value={user}
                  onChange={handleUsersChange}
                  label="Select User"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflow: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {users?.map((id, index) => (
                    <MenuItem key={index} value={id}>
                      {id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MDBox>
            <MDBox mb={2} width="100%" sx={{
              width: '200px',
              height: '10px',
            }} >
            </MDBox>
          </MDBox>
        }
        {payment === '1' &&
          <MDBox display="flex" alignItems="center" gap={1}>
            <h4 id="senderIds-label">Pay to <b style={{ color: "#F1A034" }}> {user} </b>:</h4>
            <MDInput
              type="text"
              name="user_type"
              placeholder="Enter amount in Rs."
              value={amount}
              onChange={amountSubmit}
            // error={errors.emailError}
            // disabled="true"
            />
            <MDButton variant="gradient" color="success" sx={{
              width: '100px',
              height: '20px',
            }}
              onClick={paymentDone}>
              <Icon sx={{ fontWeight: "bold" }}>money</Icon>
              &nbsp;Pay
            </MDButton>
            <MDButton variant="gradient" value='false' color="primary" onClick={handleChange}
              sx={{
                width: '100px',
                height: '20px',
              }}>
              Cancel
            </MDButton>
          </MDBox>
        }


      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDBox
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <MDBox component="img" src={masterCardLogo} alt="master card" width="10%" mr={2} />
              <MDTypography variant="h6" fontWeight="medium">
                ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;7852
              </MDTypography>
              {/* <MDBox ml="auto" lineHeight={0} color={darkMode ? "white" : "dark"}>
                <Tooltip title="Edit Card" placement="top">
                  <Icon sx={{ cursor: "pointer" }} fontSize="small">
                    edit
                  </Icon>
                </Tooltip>
              </MDBox> */}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <MDBox component="img" src={visaLogo} alt="master card" width="10%" mr={2} />
              <MDTypography variant="h6" fontWeight="medium">
                ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;5248
              </MDTypography>
              {/* <MDBox ml="auto" lineHeight={0} color={darkMode ? "white" : "dark"}>
                <Tooltip title="Edit Card" placement="top">
                  <Icon sx={{ cursor: "pointer" }} fontSize="small">
                    edit
                  </Icon>
                </Tooltip>
              </MDBox> */}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default PaymentMethod;
