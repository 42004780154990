// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

// Redux tool kit
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from './redux/dashboardSlice.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useDailyStatsMutation } from "../../services/post.js";

function Dashboard() {
  const [dailyStats] = useDailyStatsMutation();

  const dispatch = useDispatch();
  const { sales, tasks } = reportsLineChartData;
  const [content, setContent]= useState()
  // const { Today_Submit, Today_Deliver, Today_Failure, Today_Success_Percentage, data, status, error, columns_store } = useSelector((state) => state.mydashboardSlice);

  // const memoized_columns_store = useMemo(() => {
  //   return columns_store;
  // }, [columns_store]);
  const columns_store = [
    { Header: "users", accessor: "users", width: "45%", align: "left" },
    { Header: "count submit", accessor: "totalcountsubmit", align: "center" },
    { Header: "count deliver", accessor: "members", align: "center" },
    // { Header: "count submit", accessor: "totalcountsubmit", align: "center" },

    { Header: "completion", accessor: "completion", align: "center" },
  ]
  useEffect(() => {
    dailyStats()
      .then((success) => {
        if (success.data.statusCode === 200) {
          setContent(success.data)
          // toast.success("Successfully Submitted", { autoClose: 2000 });
        } else if (success?.error?.data?.statusCode > 300) {
          // toast.error(success?.error?.data?.message);
        }
      })
      .catch((error) => {
        // toast.error(error.message);
      });


    // const payload = {
    //   key1: 'value1',
    //   key2: 'value2',
    // };
    // dispatch(fetchData(payload));
  }, []);

  

  // if (status === 'loading') {
  //   content = 'Loading...';
  // } else if (status === 'succeeded') {
  //   content = JSON.stringify(data);
  // } else if (status === 'failed') {
  //   content = error;
  // } else if (status === 'idle') {
  //   content = 'idle';
  // }

  const today = new Date().toLocaleDateString();

  return (

    <DashboardLayout>
      <DashboardNavbar />

      {/* LAYER 1 */}

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="assignment_turned_in"
                title="Submit Count"
                count={content?.today_stats[0]?.Submit_count ? content?.today_stats[0]?.Submit_count : '25'}
                percentage={{
                  color: content?.percentage_difference_yesterday?.Submit_count < "0"?"error":"success",
                  amount: content?.percentage_difference_yesterday?.Submit_count ? content?.percentage_difference_yesterday?.Submit_count.toFixed(2)+"%" : '+39%',
                  label: "then yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="cloud_upload"
                title="Status Delivered"
                count={content?.today_stats[0]?.Status_delivered ? content?.today_stats[0]?.Status_delivered : '2'}
                percentage={{
                  color: content?.percentage_difference_yesterday?.Status_delivered < "0"?"error":"success",
                  amount: content?.percentage_difference_yesterday?.Status_delivered ? content?.percentage_difference_yesterday?.Status_delivered.toFixed(2)+"%" : '-88%',
                  label: "then yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="error"
                title="Status Failed"
                count={content?.today_stats[0]?.Status_failed ? content?.today_stats[0]?.Status_failed : '23'}
                percentage={{
                  color: "warning",
                  amount: content?.percentage_difference_yesterday?.Status_failed ? content?.percentage_difference_yesterday?.Status_failed.toFixed(2)+"%" : '2200',
                  label: "then yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="trending_down"
                title="Success Percentage"
                // count={`${Today_Success_Percentage}%`}
                count={content?.today_stats[0]?.success_percentage ? content?.today_stats[0]?.success_percentage.toFixed(2)+"%" : '8'}

                percentage={{
                  color: content?.percentage_difference_yesterday?.success_percentage < "0"?"error":"success",
                  amount: "",
                  amount: content?.percentage_difference_yesterday?.success_percentage ? content?.percentage_difference_yesterday?.success_percentage.toFixed(2)+"%" : '-92%',
                  label: "then Yesterday",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>

        {/* LAYER 2 */}

        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Daily SMS Stats"
                  description="Last 7 days Statistics"
                  date={`Last Updated on ${today}`}
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Success Percentage Stats"
                  description="Last 7 days success percentage"
                  // {
                  //   <>
                  //     (<strong>+15%</strong>) increase in today sales.
                  //   </>
                  // }
                  date="updated 2 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Growth Trend"
                  description="SMS Growth Trend past 6 months"
                  date="updated 2 min ago"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        {/* LAYER 3 */}

        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects myProp={columns_store} />
              {/* <Projects myProp={""} /> */}

            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
