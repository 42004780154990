import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Transaction from "layouts/billing/components/Transaction";
import { useGetTransactionalDetailMutation, useGetRegisteredUserNameMutation } from "services/post";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function Transactions() {
  const [transactions, setTransactions] = useState([]);

  const [construction] = useGetTransactionalDetailMutation();
  const [getUserName] = useGetRegisteredUserNameMutation()
  const [users, setUsers] = useState([])
  const [user, setUser] = useState("")
  const [userData, setUserData] = useState([])
  const [userId, setUserId] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await construction(userId);
        if (response.data.statusCode === 200) {
          setTransactions(response.data.data);
        } else {
          console.error("Failed to fetch transactions:", response);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };
    const fetchUserData = () => {
      getUserName()
        .then((success) => {
          if (success.data.statusCode === 200) {
            success.data.get_user_registered.map((key) => {
              setUsers((prevUsers) => [...prevUsers, key.username]);
            });
            setUserData(success.data.get_user_registered)
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }

    fetchData();
    fetchUserData();
  }, [construction, user]);


  const handleUsersChange = (event) => {
    if (event.target.value !== "") {
      const selectedUser = userData.find((u) => u.username === event.target.value);
      setUser(event.target.value);
      setUserId(selectedUser.id)
    }
    else {
      setUser(event.target.value);
      setUserId("")
    }
  }
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Transactions
        </MDTypography>
        <MDBox>
          <MDBox mb={2} width="100%" sx={{
            height: '10px',
          }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel id="senderIds-label">Select User</InputLabel>
              <Select
                labelId="senderIds-label"
                id="senderIds"
                value={user}
                onChange={handleUsersChange}
                label="Select User"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                      overflow: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {users?.map((id, index) => (
                  <MenuItem key={index} value={id}>
                    {id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox mb={2} width="100%" sx={{
            width: '200px',
            height: '10px',
          }} >
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox>

          <MDTypography variant="button" color="text" fontWeight="regular">
            {/* Display your date range here */}
            2024
          </MDTypography>
        </MDBox>

      </MDBox>

      <MDBox pt={3} pb={2} px={2} style={{ height: '550px', overflowY: 'auto' }}>
        {transactions.map((transaction) => (
          <Transaction
            key={transaction.id}
            color={transaction.amount >= 0 ? 'success' : 'error'}
            icon={transaction.amount >= 0 ? 'expand_less' : 'expand_more'}
            name={transaction.username}
            description={new Date(transaction.created_date).toLocaleString()}
            value={`${transaction.amount >= 0 ? '+' : ''} $${Math.abs(transaction.amount).toLocaleString()}`}
          />
        ))}
      </MDBox>

    </Card>
  );
}

export default Transactions;
