import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetSenderIdMutation } from 'services/post';

export default function Data(userID) {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetSenderIdMutation();

  useEffect(() => {
    // Replace 73 with the dynamic userId you want to pass
    const userId = "";

    // Fetch data from the API
    getuser(userId)
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          const fetchedData = data.data.map((item) => ({
            sender_id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.sender_id}
              </MDTypography>
            ),
            user_id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.user_id}
              </MDTypography>
            ),
            peid: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.peid}
              </MDTypography>
            ),
            status: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                ACTIVE
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser, userID]);

  return {
    columns: [
      { Header: "Sender ID", accessor: "sender_id", align: "left" },
      { Header: "User ID", accessor: "user_id", align: "left" },
      { Header: "PE ID", accessor: "peid", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
