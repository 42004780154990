
// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";

import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";

import Login from "auth/login";
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";


// @mui icons
import DLT from "./layouts/dlt/index"
import Icon from "@mui/material/Icon";
import Blacklisting from "layouts/blacklisting";
import SmsCampaign from "layouts/smsCampaign";
import Dlr from "layouts/dlr";
import Summary from "layouts/summary";
import Telco from "layouts/telco";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "DLT",
    key: "dlt",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/dlt",
    component: <DLT />,
  },
  {
    type: "collapse",
    name: "Blacklisting",
    key: "blacklisting",
    icon: <Icon fontSize="small">app_blocking</Icon>,
    route: "/blacklisting",
    component: <Blacklisting />,
  },
  {
    type: "collapse",
    name: "SMS Campaign",
    key: "smsCampaign",
    icon: <Icon fontSize="small">sms</Icon>,
    route: "/smsCampaign",
    component: <SmsCampaign />,
  },
  {
    type: "collapse",
    name: "DLR",
    key: "dlr",
    icon: <Icon fontSize="small">layers</Icon>,
    route: "/dlr",
    component: <Dlr />,
  },
  {
    type: "collapse",
    name: "Summary",
    key: "summary",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/summary",
    component: <Summary />,
  },
  {
    type: "collapse",
    name: "Telco Onboarding",
    key: "telco",
    icon: <Icon fontSize="small">how_to_reg</Icon>,
    route: "/telco",
    component: <Telco />,
  },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  {
    type: "collapse",
    name: "User OnBoarding",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-onboarding",
    component: <UserProfile />,
  },
  {
    type: "collapse",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/user-management",
    component: <UserManagement />,
  }
  ,
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
  },
  {
    type: "auth",
    name: "Register",
    key: "register",
    icon: <Icon fontSize="small">reigster</Icon>,
    route: "/auth/register",
    component: <Register />,
  },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/reset-password",
    component: <ResetPassword />,
  },
];

export default routes;
