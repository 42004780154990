import React, { useState, useEffect } from 'react';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useGetRegisteredAccountsListMutation } from 'services/post';

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getRegisteredAccount] = useGetRegisteredAccountsListMutation();

  useEffect(() => {
    // Fetch data from the API
    getRegisteredAccount()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          const fetchedData = data.get_registered_accounts.map((account) => ({
            department_name: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.department_name}
              </MDTypography>
            ),
            system_id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.system_id}
              </MDTypography>
            ),
            parent: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.parent}
              </MDTypography>
            ),
            route: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.Route}
              </MDTypography>
            ),
            channel: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.channel}
              </MDTypography>
            ),
            max_binds: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.max_binds}
              </MDTypography>
            ),
            throughput: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.throughput}
              </MDTypography>
            ),
            default_cost: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.default_cost}
              </MDTypography>
            ),
            refund_amount: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.refund_amount}
              </MDTypography>
            ),
            sender_type: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {account.sender_type}
              </MDTypography>
            ),
            // action: (
            //   <MDBox>
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" mr={2}>
            //       Edit
            //     </MDTypography>
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //       Delete
            //     </MDTypography>
            //   </MDBox>
            // ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getRegisteredAccount]);

  return {
    columns: [
      { Header: "Department Name", accessor: "department_name", align: "left" },
      { Header: "System ID", accessor: "system_id", align: "left" },
      { Header: "Parent", accessor: "parent", align: "left" },
      { Header: "Route", accessor: "route", align: "left" },
      { Header: "Channel", accessor: "channel", align: "left" },
      { Header: "Max Binds", accessor: "max_binds", align: "left" },
      { Header: "Throughput", accessor: "throughput", align: "left" },
      { Header: "Default Cost", accessor: "default_cost", align: "left" },
      { Header: "Refund Amount", accessor: "refund_amount", align: "left" },
      { Header: "Sender Type", accessor: "sender_type", align: "left" },
      // { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}