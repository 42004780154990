import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetDlrRepushMutation } from 'services/post';

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetDlrRepushMutation();

  useEffect(() => {

    // Fetch data from the API
    getuser()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          const fetchedData = data.dlr_repush_result.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            username: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.sender}
              </MDTypography>
            ),
            accountusername: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account}
              </MDTypography>
            ),
            todate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.time * 1000).toLocaleString()}
                {/* {item.time} */}
              </MDTypography>
            ),
            fromdate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.time * 1000).toLocaleString()}

              </MDTypography>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "User Name", accessor: "username", align: "left" },
      { Header: "Account User Name", accessor: "accountusername", align: "left" },
      { Header: "To Date", accessor: "todate", align: "left" },
      { Header: "From Date", accessor: "fromdate", align: "left" },
    ],
    rows: rows,
  };
}
