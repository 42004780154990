import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
// import { AuthContextProvider } from "context";
import { store } from "./app/store";
import { Provider } from "react-redux";
// import store from "./store/store.js";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    {/* <AuthContextProvider> */}
      <MaterialUIControllerProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </MaterialUIControllerProvider>
    {/* </AuthContextProvider> */}
  </BrowserRouter>
);