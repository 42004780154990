

export default {
  sales: {
    labels: [
      "S",
      "M",
      "T",
      "W",
      "T",
      "F",
      "S"
    ],
    datasets: {
      label: "Success Rate", data: ["95.31250",
        "100.00000",
        "78.98681",
        "70.69496",
        "76.84144",
        "85.27970",
        "80.96381"]
    },
  },
  tasks: {
    labels: ["Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: { label: "Growth Trend", data: [0.3525, 0.4700, 0.6077, 0.7263, 2.2624, 0.1545, 0.1241] },
  },
};
