// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoJira from "assets/images/small-logos/logo-jira.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";

import { useAccountWise7DaysDetailMutation } from "services/post";
import { useEffect, useState } from "react";

export default function data() {
  const [accountDetails] = useAccountWise7DaysDetailMutation();
  const [content, setContent] = useState(null);

  useEffect(() => {
    accountDetails()
      .then((success) => {
        if (success.data.statusCode === 200) {
          setContent(success.data);
        } else if (success?.error?.data?.statusCode > 300) {
          // Handle error if needed
        }
      })
      .catch((error) => {
        // Handle error if needed
      });
  }, []);

  const Company = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  const logoMapping = {
    "admin#cap_innotek": logoXD,
    "admin#celetel_sim": logoAtlassian,
    "admin#cele_horizen#cel_horizen": logoSlack,
    "admin#cap_pwr": logoSpotify,
    "admin#smart_tech": logoJira,
    "admin#vapp": logoInvesion,
    // Add mappings for other users if needed
  };

  const rows = content && content.result.length > 0
    ? content.result.map((item) => {
      const { user, total_submit_count, total_deliver_count } = item;
      const completionPercentage =
        (total_deliver_count / total_submit_count) * 100;

      return {
        users: (
          <Company
            image={logoMapping[user] || logoXD} // Default to logoXD if user not found in mapping
            name={user}
          />
        ),
        members: (
          <MDBox display="flex" py={1}>
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {total_deliver_count}
            </MDTypography>
          </MDBox>
        ),
        totalcountsubmit: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {total_submit_count}
          </MDTypography>
        ),
        completion: (
          <Progress
            color="info"
            value={completionPercentage}
          />
        ),
      };
    })
    : [{
      users: (
        <MDBox display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
          <MDTypography variant="caption" color="text" fontWeight="medium">
            No Data Found
          </MDTypography>
        </MDBox>
      ),
      members: null,
      totalcountsubmit: null,
      completion: null,
    }];

  return {
    rows,
  };
}
