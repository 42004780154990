import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useCampaignTrackingMutation } from 'services/post';

const Data = ( postData ) => {  
  const [rows, setRows] = useState([]);
  const [campaignTracking] = useCampaignTrackingMutation();
  useEffect(() => {
    campaignTracking(postData)
      .unwrap()
      .then((response) => {
        if (response?.statusCode === 200) {
          const fetchedData = response.campaign_tracking.map((item) => ({
            campaignName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.campaign_name}
              </MDTypography>
            ),
            contact: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.contact}
              </MDTypography>
            ),
            keyword: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.keyword}
              </MDTypography>
            ),
            url: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.url}
              </MDTypography>
            ),
            createdDate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.created_date).toLocaleString()}
              </MDTypography>
            ),
            clicks: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.clicks}
              </MDTypography>
            ),
          }));
          setRows(fetchedData);
        } else if (response.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [campaignTracking, postData]);

  return {
    columns: [
      { Header: "Campaign Name", accessor: "campaignName", align: "left" },
      { Header: "Contact", accessor: "contact", align: "left" },
      { Header: "Keyword", accessor: "keyword", align: "left" },
      { Header: "URL", accessor: "url",align: "left" },
      { Header: "Created Date", accessor: "createdDate", align: "left" },
      { Header: "Clicks", accessor: "clicks", align: "left" },
    ],
    rows: rows,
  };
};

export default Data;
