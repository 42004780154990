import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetTemplateSenderIdMutation } from 'services/post';
import "../../../assets/styles/styles.css"

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetTemplateSenderIdMutation();

  useEffect(() => {
    // Replace with the dynamic user you want to pass
    const user = 'admin#ABC Reseller#XYZ#Sanjay Enterprises';
    const limit = 2;
    const offset = 0;

    // Fetch data from the API
    getuser({ user, limit, offset })
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          const fetchedData = data.template_sender_id_mapping_data.map((item) => ({
            sender_id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            template: (
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="medium"
                title={item.template}
                className="truncate-text"
              >
                {item.template ? item.template : "-"}
              </MDTypography>
            ),
            template_id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.contentid ? item.contentid : "-"}
              </MDTypography>
            ),
            peid: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.peid}
              </MDTypography>
            ),
            user: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.user}
              </MDTypography>
            ),
            sender: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.sender}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser]);

  return {
    columns: [
      { Header: "Sender ID", accessor: "sender_id", align: "left", width:100},
      { Header: "Template", accessor: "template", align: "left" },
      { Header: "Template ID", accessor: "template_id", align: "left" },
      { Header: "PEID", accessor: "peid", align: "left" },
      { Header: "user", accessor: "user", align: "left" },
      { Header: "sender", accessor: "sender", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
