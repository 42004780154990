import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import "./../../assets/styles/styles.css"

// Overview page components
import Header from "layouts/user-profile/Header";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useRegisterUserMutation, useGetUserTypeMutation, useRegisterAccountMutation } from "services/post";
// import MDSnackbar from "components/MDSnackbar";
import MDSnackbar from "components/MDSnackbar";
import Card from "@mui/material/Card";

const UserProfile = () => {
  const [isDemo, setIsDemo] = useState(false);
  const [notification, setNotification] = useState(false);
  const [registerUser] = useRegisterUserMutation()
  const [accountUserReg] = useRegisterAccountMutation();
  const [getUserType] = useGetUserTypeMutation()
  const [content, setContent] = useState()
  const [userType, setUserType] = useState()
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [message, setMessage]= useState("")

  const closeSuccessSB = () => setSuccessSB(false);
  const closeErrorSB = () => setErrorSB(false);

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    parentId: "35",
    wlId: "2",
    username: "",
    mobile: "",
    isallowIp: "",
    allowIp: "",
    isTfa: "",
    tfaNumber: "",
    accountValidity: "",
    billingCycle: "1",
    billingType: "2",
    enterpriseName: "",
    designation: "",
    accountName: "",
    userChain: ""
  });
  const [accountUser, setAccountUser] = useState({
    department_name: "",
    parent: "",
    created_by: "",
    channel: "",
    route: "",
    system_id: "",
    password: "",
    throughput: 500,
    max_binds: 10,
    dlt: 0,
    callback_url: "",
    callback_method: "GET",
    default_cost: 0.01,
    dlt_cost: 0.12,
    refund_amount: 0.98,
    sender_type: "ANY",
    spam: "",
  });
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [errors, setErrors] = useState({
    /* Users Errors */
    parentIdError: false,
    wlIdError: false,
    nameError: false,
    emailError: false,
    usernameError: false,
    passError: false,
    mobileError: false,
    isallowIpError: false,
    allowIpError: false,
    isTfaError: false,
    tfaNumberError: false,
    accountValidityError: false,
    billingCycleError: false,
    billingTypeError: false,
    enterpriseNameError: false,
    designationError: false,
    accountNameError: false,
    userChainError: false,
    /* Account Errors */
    department_nameError: false,
    parentError: false,
    created_byError: false,
    channelError: false,
    routeError: false,
    system_idError: false,
    passwordError: false,
    throughputError: false,
    max_bindsError: false,
    dltError: false,
    callback_urlError: false,
    callback_methodError: false,
    default_costError: false,
    dlt_costError: false,
    refund_amountError: false,
    sender_typeError: false,
    spamError: false,
  });

  useEffect(() => {
    getUserType()
      .then((success) => {
        if (success.data.statusCode === 200) {
          setUserType(success.data)
          // toast.success("Successfully Submitted", { autoClose: 2000 });
        } else if (success?.error?.data?.statusCode > 300) {
          // toast.error(success?.error?.data?.message);
        }
      })
      .catch((error) => {
        // toast.error(error.message);
      });



    // if (notification === true) {
    //   setTimeout(() => {
    //     setNotification(false);
    //   }, 5000);
    // }
  }, []);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const changeAccountHandler = (e) => {
    setAccountUser({
      ...accountUser,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (value === "user") {
      console.log('USER::::::::::::;', user);
      const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (user.parentId.trim().length === 0) {
        setErrors({ ...errors, parentIdError: true });
        return;
      }
      if (user.wlId.trim().length === 0) {
        setErrors({ ...errors, wlIdError: true });
        return;
      }
      if (user.name.trim().length === 0) {
        setErrors({ ...errors, nameError: true });
        return;
      }
      if (user.email.trim().length === 0 || !user.email.trim().match(mailFormat)) {
        setErrors({ ...errors, emailError: true });
        return;
      }
      if (user.username.trim().length === 0) {
        setErrors({ ...errors, usernameError: true });
        return;
      }
      if (user.password.trim().length === 0) {
        setErrors({ ...errors, passError: true });
        return;
      }
      if (user.mobile.trim().length === 0) {
        setErrors({ ...errors, mobileError: true });
        return;
      }
      if (user.allowIp.trim().length === 0) {
        setErrors({ ...errors, allowIpError: true });
        return;
      }
      if (user.tfaNumber.trim().length === 0) {
        setErrors({ ...errors, tfaNumberError: true });
        return;
      }
      if (user.accountValidity.trim().length === 0) {
        setErrors({ ...errors, accountValidityError: true });
        return;
      }
      if (user.enterpriseName.trim().length === 0) {
        setErrors({ ...errors, enterpriseNameError: true });
        return;
      }
      if (user.designation.trim().length === 0) {
        setErrors({ ...errors, designationError: true });
        return;
      }
      if (user.accountName.trim().length === 0) {
        setErrors({ ...errors, accountNameError: true });
        return;
      }
      if (user.userChain.trim().length === 0) {
        setErrors({ ...errors, userChainError: true });
        return;
      }
      let userData = {
        "user_type": 1,
        "parent_id": user.parentId,
        "wl_id": user.wlId,
        "name": user.name,
        "username": user.username,
        "password": user.password,
        "email": user.email, //VERIFY API
        "mobile": user.mobile, //VERIFY API
        "is_allow_ip": 0, //RADIO BUTTON
        "allow_ip": user.allowIp, //IP SUBMISSION INPUT BOX
        "is_tfa": 0, //STATIC RADIO
        "tfa_no": user.tfaNumber, //STATIC INPUT
        "account_validity": user.accountValidity, //CALENDAR WITH FUTURE DATE ONLY
        "billing_cycle": user.billingCycle, //API FOR IT
        "billing_type": user.billingType, //API FOR IT
        "account_status": 1, //DEFAULT:-TRUE NOT IN FORM
        "enterprise_name": user.enterpriseName,
        "designation": user.designation,
        "account_name": user.accountName,
        "is_deleted": 0, //STATIC:- NOT MANDATORY NOT IN FORM AS WELL
        "user_chain": user.userChain //GOT CHAIN WHILE LOGIN :- ASDJNASJNDJA ADD => # IN SUFFIX
      }

      registerUser(userData)
        .then((success) => {
          console.log('HIT in user', success);
          if (success.data.statusCode === 200) {
            setContent(success.data)
            setMessage(success.data.message)
            setSuccessSB(true)
            setNotification(true);
            setValue("")
            window.location.reload()
            // toast.success("Successfully Submitted", { autoClose: 2000 });
          } else if (success?.error?.data?.statusCode > 300) {
            setErrorSB(true);
            // toast.error(success?.error?.data?.message);
            alert(success?.error?.data?.message)

          }
        })
        .catch((error) => {
          // toast.error(error.message);
          setMessage(error.message)

        }); setNotification(true);


      // call api for update
      // const response = await AuthService.updateProfile(JSON.stringify(userData));

      // reset errors
      setErrors({
        parentIdError: false,
        wlIdError: false,
        nameError: false,
        emailError: false,
        usernameError: false,
        passError: false,
        mobileError: false,
        isallowIpError: false,
        allowIpError: false,
        isTfaError: false,
        tfaNumberError: false,
        accountValidityError: false,
        billingCycleError: false,
        billingTypeError: false,
        enterpriseNameError: false,
        designationError: false,
        accountNameError: false,
        userChainError: false,
      });

    }
    else {
      if (accountUser.department_name.length === 0) {
        setErrors({ ...errors, department_nameError: true });
        return;
      }
      if (accountUser.parent.length === 0) {
        setErrors({ ...errors, parentError: true });
        return;
      }
      if (accountUser.created_by.length === 0) {
        setErrors({ ...errors, created_byError: true });
        return;
      }
      if (accountUser.channel.length === 0) {
        setErrors({ ...errors, channelError: true });
        return;
      }
      if (accountUser.route.length === 0) {
        setErrors({ ...errors, routeError: true });
        return;
      }
      if (accountUser.system_id.length === 0) {
        setErrors({ ...errors, system_idError: true });
        return;
      }
      if (accountUser.password.length === 0) {
        setErrors({ ...errors, passwordError: true });
        return;
      }
      if (accountUser.throughput.length === 0) {
        setErrors({ ...errors, throughputError: true });
        return;
      }
      if (accountUser.max_binds.length === 0) {
        setErrors({ ...errors, max_bindsError: true });
        return;
      }
      if (accountUser.dlt.length === 0) {
        setErrors({ ...errors, dltError: true });
        return; accountUserReg
      }
      if (accountUser.callback_url.length === 0) {
        setErrors({ ...errors, callback_urlError: true });
        return;
      }
      if (accountUser.callback_method.length === 0) {
        setErrors({ ...errors, callback_methodError: true });
        return;
      }
      if (accountUser.default_cost.length === 0) {
        setErrors({ ...errors, default_costError: true });
        return;
      }
      if (accountUser.dlt_cost.length === 0) {
        setErrors({ ...errors, dlt_costError: true });
        return;
      }
      if (accountUser.refund_amount.length === 0) {
        setErrors({ ...errors, refund_amountError: true });
        return;
      }
      if (accountUser.sender_type.length === 0) {
        setErrors({ ...errors, sender_typeError: true });
        return;
      }
      if (accountUser.spam.length === 0) {
        setErrors({ ...errors, spamError: true });
        return;
      }
      let userData = {
        "department_name": accountUser.department_name, //anything
        "parent": accountUser.parent, //chain
        "created_by": accountUser.created_by, //login accountUser id
        "channel": accountUser.channel, //SMPP/WEB/HTTP
        "route": accountUser.route,//value from another route API 
        "system_id": accountUser.system_id, //unique name
        "password": accountUser.password, //string
        "throughput": accountUser.throughput, //TPS
        "max_binds": accountUser.max_binds, //sessions
        "dlt": accountUser.dlt, //0=default,1=open session,2=open template,3=whitelisted
        "callback_url": accountUser.callback_url,
        "callback_method": accountUser.callback_method, //GET/POST
        "default_cost": accountUser.default_cost,//anything
        "dlt_cost": accountUser.dlt_cost,//anything
        "refund_amount": accountUser.refund_amount,//anything
        "sender_type": accountUser.sender_type,//PROMO/TRANS/OTP default is ANY
        "spam": accountUser.spam,//global/user
        "callback_body": "",
        "receiver": "00",
        "senderid": "00",
        "contentid": "00"
      }


      accountUserReg(userData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            console.log('HIT in account error3', success);
            setMessage(success.data.message)
            setContent(success.data)
            setSuccessSB(true)
            setNotification(true);
            setValue("")
            window.location.reload()
            // toast.success("Successfully Submitted", { autoClose: 2000 });
          } else if (success.error.data.statusCode > 200) {
            console.log('HIT in account error2', success);

            setMessage(error.message)
            setErrorSB(true);
            // toast.error(success?.error?.data?.message);
            // alert(success?.error?.data?.message)

          }
          else {
            console.log('HIT in account error', success);

            setMessage(error.message)
            setErrorSB(true);
            // toast.error(success?.error?.data?.message);
            // alert(success?.error?.data?.message)
            console.log('HIT in account error', success);

          }
        })
        .catch((error) => {
          console.log('HIT in account error3', error);
          setMessage(error.message)
          setErrorSB(true);
          // toast.error(error.message);
        });

      setErrors({
        department_name: false,
        parent: false,
        created_by: false,
        channel: false,
        route: false,
        system_id: false,
        password: false,
        throughput: false,
        max_binds: false,
        dlt: false,
        callback_url: false,
        callback_method: false,
        default_cost: false,
        dlt_cost: false,
        refund_amount: false,
        sender_type: false,
        spam: false,
      });

    }

  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Material Dashboard"
      content={message}
      dateTime="1 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content={message}
      dateTime="1 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );



  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderSuccessSB}
      {renderErrorSB}
      <MDBox mb={2} />
      {value === "" &&
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="76vh"
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Choose Registration Type</FormLabel>
            <RadioGroup
              className="radio-group"
              aria-label="fav_language"
              name="fav_language"
              value={value}
              onChange={handleChange}
              row
            >
              <FormControlLabel value="user" control={<Radio />} label="User" />
              <FormControlLabel value="account" control={<Radio />} label="Account" />
            </RadioGroup>
          </FormControl>
        </Box>
      }
      {value === "user" &&
        <Header name="User Registration">
          {notification && (
            <MDAlert color="info" mt="20px">
              <MDTypography variant="body2" color="white">
                User has been registered
              </MDTypography>
            </MDAlert>
          )}
          <MDBox
            component="form"
            role="form"
            onSubmit={submitHandler}
            display="flex"
            flexDirection="column"
          >
            <MDBox display="flex" flexDirection="row" mt={3} mb={1}>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  User Type
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="user_type"
                    value={userType.user_type[0].id ? userType.user_type[0].id : "1"}
                    onChange={changeHandler}
                    // error={errors.emailError}
                    disabled="true"
                  />
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Parent ID
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="parentId"
                    value={user.parentId}
                    onChange={changeHandler}
                    error={errors.parentIdError}
                    // placeholder="Parent ID"
                    disabled="true"
                  />
                  {errors.parentIdError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The parent ID can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Wl ID
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="wlId"
                    value={user.wlId}
                    onChange={changeHandler}
                    error={errors.wlIdError}
                    // placeholder="WL ID"
                    disabled="true"
                  />
                  {errors.wlIdError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The Wl ID can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Name
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="name"
                    fullWidth
                    name="name"
                    value={user.name}
                    onChange={changeHandler}
                    error={errors.nameError}
                    placeholder="Name"
                  />
                  {errors.nameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The name can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Email
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="email"
                    fullWidth
                    name="email"
                    value={user.email}
                    onChange={changeHandler}
                    error={errors.emailError}
                    placeholder="Email"
                  />
                  {errors.emailError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The email must be valid
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="row" mb={1}>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Username
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="username"
                    value={user.username}
                    onChange={changeHandler}
                    error={errors.usernameError}
                    placeholder="Username"
                  />
                  {errors.usernameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The username can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Password
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="password"
                    fullWidth
                    name="password"
                    value={user.password}
                    onChange={changeHandler}
                    error={errors.passError}
                    placeholder="Password"
                  />
                  {errors.passError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The password can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Mobile Number
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="phone"
                    fullWidth
                    name="mobile"
                    value={user.mobile}
                    onChange={changeHandler}
                    error={errors.mobileError}
                    placeholder="Mobile Number"
                  />
                  {errors.mobileError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The phone number can not be empty
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Allowed IP
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="name"
                    fullWidth
                    name="allowIp"
                    value={user.allowIp}
                    onChange={changeHandler}
                    error={errors.allowIpError}
                    placeholder="Enter IP"
                  />
                  {errors.allowIpError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The IP can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  TFA Number
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="tfaNumber"
                    value={user.tfaNumber}
                    onChange={changeHandler}
                    error={errors.tfaNumberError}
                    placeholder="Enter TFA Number"
                  />
                  {errors.tfaNumberError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The TFA number cannot be null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="row" mb={1}>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Account Validity
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="date"
                    fullWidth
                    name="accountValidity"
                    value={user.accountValidity}
                    onChange={changeHandler}
                    error={errors.accountValidityError}
                    placeholder="Account Validity"
                  />
                  {errors.accountValidityError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The account validity date cannot be null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Billing Cycle
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="parentId"
                    value="1"
                    disabled
                  // onChange={changeHandler}
                  // error={errors.parentIdError}

                  />
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Billing Type
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="parentId"
                    value="2"
                    disabled
                  // onChange={changeHandler}
                  // error={errors.parentIdError}

                  />
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Enterprise Name
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="name"
                    fullWidth
                    name="enterpriseName"
                    value={user.enterpriseName}
                    onChange={changeHandler}
                    error={errors.enterpriseNameError}
                    placeholder="Enterprise Name"
                  />
                  {errors.enterpriseNameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The enterprise name can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Designation
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="designation"
                    value={user.designation}
                    onChange={changeHandler}
                    error={errors.designationError}
                    placeholder="Designation"
                  />
                  {errors.emailError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The designation cannot be null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="column" mb={1}>
              <MDBox display="flex" flexDirection="row">
                <MDBox display="flex" flexDirection="column">
                  <MDBox display="flex" flexDirection="row">
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      mr={2}
                    >
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Account Name
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          type="text"
                          fullWidth
                          name="accountName"
                          placeholder="Account Name"
                          value={user.accountName}
                          onChange={changeHandler}
                          error={errors.accountNameError}
                        />
                        {errors.accountNameError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            The account name cannot be null
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      ml={2}
                    >
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        User Chain
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          type="text"
                          fullWidth
                          name="userChain"
                          value={user.userChain}
                          onChange={changeHandler}
                          error={errors.userChainError}
                          placeholder="User Chain"
                        />
                        {errors.userChainError && (
                          <MDTypography variant="caption" color="error" fontWeight="light">
                            The user chain can not be null or empty or invalid.
                          </MDTypography>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>

              </MDBox>

              <MDBox mt={4} display="flex" justifyContent="end" gap={1}>
                <MDButton variant="gradient" color="warning" value="" onClick={handleChange} >
                  Cancel
                </MDButton>
                <MDButton variant="gradient" color="info" type="submit">
                  Submit
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Header>}
      {value === "account" &&
        <Header name="Account Registration">
          {notification && (
            <MDAlert color="info" mt="20px">
              <MDTypography variant="body2" color="white">
                Account has been registered
              </MDTypography>
            </MDAlert>
          )}
          <MDBox
            component="form"
            role="form"
            onSubmit={submitHandler}
            display="flex"
            flexDirection="column"
          >
            <MDBox display="flex" flexDirection="row" mt={3} mb={1}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Department Name
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="department_name"
                    value={accountUser.department_name}
                    onChange={changeAccountHandler}
                    error={errors.department_nameError}
                    placeholder="Department Name"

                  />
                  {errors.department_nameError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The department name can not be null or empty or invalid.
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Parent
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="parent"
                    value={accountUser.parent}
                    onChange={changeAccountHandler}
                    error={errors.parentError}
                    placeholder="Parent"
                  />
                  {errors.parentError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The parent must be valid
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Created By
                </MDTypography>
                <MDBox mb={2} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="created_by"
                    placeholder="Created By"
                    value={accountUser.created_by}
                    onChange={changeAccountHandler}
                    error={errors.created_by}
                  />
                  {errors.created_by && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The Created by cannot be invalid or empty.                      </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Channel
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="channel"
                    placeholder="Channel"
                    value={accountUser.channel}
                    onChange={changeAccountHandler}
                    error={errors.channelError}

                  />
                  {errors.channelError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The Channel must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Route
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="route"
                    placeholder="Route"
                    value={accountUser.route}
                    onChange={changeAccountHandler}
                    error={errors.routeError}

                  />
                  {errors.routeError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The Route must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="row" mb={1}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Callback Method
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="callback_method"
                    placeholder="Callback Method"
                    value={accountUser.callback_method}
                    onChange={changeAccountHandler}
                    disabled
                    error={errors.callback_methodError}

                  />
                  {errors.callback_methodError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The callback method must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Default Cost
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="default_cost"
                    disabled
                    placeholder="Default Cost"
                    value={accountUser.default_cost}
                    onChange={changeAccountHandler}
                    error={errors.default_costError}

                  />
                  {errors.default_costError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The default cost must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  DLT Cost
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="dlt_cost"
                    disabled
                    placeholder="DLT Cost"
                    value={accountUser.dlt_cost}
                    onChange={changeAccountHandler}
                    error={errors.dlt_costError}

                  />
                  {errors.dlt_costError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The dlt cost must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Refund Amount
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="refund_amount"
                    placeholder="Refund Amount"
                    disabled
                    value={accountUser.refund_amount}
                    onChange={changeAccountHandler}
                    error={errors.refund_amountError}

                  />
                  {errors.refund_amountError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The refund amount must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Sender Type
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="sender_type"
                    disabled
                    placeholder="Sender Type"
                    value={accountUser.sender_type}
                    onChange={changeAccountHandler}
                    error={errors.sender_typeError}

                  />
                  {errors.sender_typeError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The sender type must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>


            </MDBox>
            <MDBox display="flex" flexDirection="row" mb={1}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  System ID
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="system_id"
                    placeholder="Route"
                    value={accountUser.system_id}
                    onChange={changeAccountHandler}
                    error={errors.system_idError}

                  />
                  {errors.system_idError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The System ID must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Password
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="password"
                    fullWidth
                    name="password"
                    placeholder="Password"
                    value={accountUser.password}
                    onChange={changeAccountHandler}
                    error={errors.passwordError}

                  />
                  {errors.passwordError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The password must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Throughput
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="throughput"
                    disabled
                    placeholder="Throughput"
                    value={accountUser.throughput}
                    onChange={changeAccountHandler}
                    error={errors.throughputError}

                  />
                  {errors.throughputError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The throughput must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Max Binds
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    disabled
                    name="max_binds"
                    placeholder="Max Binds"
                    value={accountUser.max_binds}
                    onChange={changeAccountHandler}
                    error={errors.max_bindsError}

                  />
                  {errors.max_bindsError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The max binds must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>

              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  DLT
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="dlt"
                    placeholder="DLT"
                    disabled
                    value={accountUser.dlt}
                    onChange={changeAccountHandler}
                    error={errors.dltError}

                  />
                  {errors.dltError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The DLT must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>

            <MDBox display="flex" flexDirection="row" mb={1}>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                mr={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Callback URL
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="callback_url"
                    placeholder="Callback URL"
                    value={accountUser.callback_url}
                    onChange={changeAccountHandler}
                    error={errors.callback_urlError}

                  />
                  {errors.callback_urlError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The callback URL must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>

              </MDBox>
              <MDBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
                ml={2}
              >
                <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                  Spam
                </MDTypography>
                <MDBox mb={1} width="100%">
                  <MDInput
                    type="text"
                    fullWidth
                    name="spam"
                    placeholder="Spam"
                    value={accountUser.spam}
                    onChange={changeAccountHandler}
                    error={errors.spamError}

                  />
                  {errors.spamError && (
                    <MDTypography variant="caption" color="error" fontWeight="light">
                      The spam must not be empty or null
                    </MDTypography>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox mt={4} display="flex" justifyContent="end" gap={1}>
              <MDButton variant="gradient" color="warning" value="" onClick={handleChange} >
                Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" type="submit">
                Submit
              </MDButton>
            </MDBox>
          </MDBox>
        </Header>}

      <Footer />
    </DashboardLayout>
  );
};

export default UserProfile;
