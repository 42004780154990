import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useRequestListMutation } from 'services/post';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MDBox from 'components/MDBox';
import { GetApp } from '@mui/icons-material';


const Data = (downloadData) => {
  const [rows, setRows] = useState([]);
  const [downloadRequest] = useRequestListMutation();
  const handleDownload = (url) => {
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = url;
    anchor.download = url.split('/').pop(); // Extracts the filename from the URL for download
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  useEffect(() => {
    downloadRequest()
      .then((response) => {
        console.log('RESPONSE:::---', response.data.data);
        if (response?.data.statusCode === 200) {
          const fetchedData = response?.data?.data.map((item) => ({
            user: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.user_id}
              </MDTypography>
            ),
            account: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account}
              </MDTypography>
            ),
            toDate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.todate).toLocaleString()}
              </MDTypography>
            ),
            fromDate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.fromDate).toLocaleString()}
              </MDTypography>
            ),
            link: (

              <MDTypography variant="caption" color="text" fontWeight="medium">

                <IconButton
                  aria-label="download"
                  onClick={() => handleDownload(item.filePath)}
                >
                  <GetApp fontSize="small" />
                </IconButton>
              </MDTypography>
            ),
            status: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.file_status}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),

          }));
          setRows(fetchedData);
        } else if (response.data.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [downloadRequest, downloadData]);

  return {
    columns: [
      { Header: "User", accessor: "user", align: "left" },
      { Header: "Account", accessor: "account", align: "left" },
      { Header: "To Date", accessor: "toDate", align: "left" },
      { Header: "From Date", accessor: "fromDate", align: "left" },
      { Header: "Link", accessor: "link", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },
    ],
    rows: rows,
  };
};

export default Data;
