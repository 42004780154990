import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetCampaignRegistrationMutation } from 'services/post';
import "../../../assets/styles/styles.css"

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetCampaignRegistrationMutation();

  useEffect(() => {

    getuser()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          console.log("console:::---", data);
          const fetchedData = data.campaign_registration.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            campaignname: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.campaign_name}
              </MDTypography>
            ),
            header: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.header}
              </MDTypography>
            ),
            peid: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.peid ? item.peid : "-"}
              </MDTypography>
            ),
            template: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.template ? item.template : "-"}
              </MDTypography>
            ),
            message: (
              <MDTypography
                variant="caption"
                color="text"
                fontWeight="medium"
                title={item.message}
                className="truncate-text"
              >
                {item.message ? item.message : "-"}
              </MDTypography>
            ),
            account: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account ? item.account : "-"}
              </MDTypography>
            ),
            trackingurl: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.tracking_url ? item.tracking_url : "-"}
              </MDTypography>
            ),
            createddate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.created_date ? new Date(item.created_date).toLocaleString('en-US', { timeZone: 'UTC' }) : "-"}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "campaign name", accessor: "campaignname", align: "left" },
      { Header: "Header", accessor: "header", align: "left" },
      { Header: "PEID", accessor: "peid", align: "left" },
      { Header: "template", accessor: "template", align: "center" },
      { Header: "message", accessor: "message", align: "center" },
      { Header: "account", accessor: "account", align: "center" },
      { Header: "tracking URL", accessor: "trackingurl", align: "center" },
      { Header: "Created Date", accessor: "createddate", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
