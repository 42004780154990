import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useAddUrlShortenMutation, useAddAddressBookMutation, useGetRegisteredUserNameMutation, useErrorCodeMappingMutation, useTemplateRegistrationMutation, useBulkTemplateRegistrationMutation, useCampaignRegistrationMutation, useGetCampaignRegistrationMutation } from "services/post";
import authorsTableData from "layouts/tables/data/authorsTableData";
import templateMappingData from "layouts/dlt/data/templateMappingData";

import shortenURLData from "./data/shortenURLData";
import addressBookData from "./data/addressBookData";
import errorCodeData from "./data/errorCodeData";
import templateRegistrationData from "./data/templateRegistrationData";
import campaignRegistrationData from "./data/campaignRegistrationData";
import composeCampaignData from "./data/composeCampaignData"


import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox, Icon, Input, TextField } from "@mui/material";

function SmsCampaign() {
  const { columns: sColumns, rows: sRows } = shortenURLData();
  const { columns: aColumns, rows: aRows } = addressBookData();
  const { columns: eColumns, rows: eRows } = errorCodeData();
  const { columns: tColumns, rows: tRows } = templateRegistrationData();
  const { columns: cColumns, rows: cRows } = campaignRegistrationData();
  const { columns: ccColumns, rows: ccRows } = composeCampaignData();


  const [value, setValue] = useState("url_shorten");

  const [file, setFile] = useState(null);


  const [shortenURLAPI] = useAddUrlShortenMutation();
  const [addressBookAPI] = useAddAddressBookMutation();
  const [errorCodeMappingAPI] = useErrorCodeMappingMutation();
  const [templateRegManualAPI] = useTemplateRegistrationMutation();
  const [templateBulkRegAPI] = useBulkTemplateRegistrationMutation();
  const [campaignRegAPI] = useCampaignRegistrationMutation();


  // const [mapTemplateSenderId] = useMapTemplateSenderIdMutation();
  const [error, setError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const fileInputRef = useRef(null);



  // For SHORTEN URL
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")
  const [groupName, setGroupName] = useState("")
  const [errorAccount, setErrorAccount] = useState("")
  const [errorUserChain, setErrorUserChain] = useState("")
  const [errorSmsc, setErrorSmsc] = useState("")
  const [errorActual, setErrorActual] = useState("")
  const [errorUpdatedCode, setErrorUpdatedCode] = useState("")
  const [errorUpdatedStatus, setErrorUpdatedStatus] = useState("")
  const [errorDescription, setErrorDescription] = useState("")
  const [regType, setRegType] = useState("manual")
  const [templateName, setTemplateName] = useState("")
  const [templateHeader, setTemplateHeader] = useState("")
  const [templatePeid, setTemplatePeid] = useState("")
  const [templateContentId, setTemplateContentId] = useState("")
  const [template, setTemplate] = useState("")

  const [campaignAccount, setCampaignAccount] = useState("");
  const [campaignpeid, setCampaignpeid] = useState("");
  const [campaignheader, setCampaignheader] = useState("");
  const [campaigntemplate, setCampaigntemplate] = useState("");
  const [campaignmessage, setCampaignmessage] = useState("");
  const [campaigntrackingUrl, setCampaigntrackingUrl] = useState("");
  const [campaigncampaignName, setCampaigncampaignName] = useState("");
  const [message, setMessage] = useState("")
  const [campaignName, setCampaignName] = useState("")
  const [campaignNameData, setCampaignNameData] = useState([])
  const [campaignData] = useGetCampaignRegistrationMutation();
  const [exportUser, setExportUser] = useState('')
  const [getUserName] = useGetRegisteredUserNameMutation()
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState([])
  const [usersData, setUsersData] = useState([])










  useEffect(() => {

    getUserName()
      .then((success) => {
        if (success.data.statusCode === 200) {
          success.data.get_user_registered.map((key) => {
            setUsers((prevUsers) => [...prevUsers, key.username]);
          });
          setUsersData(success.data.get_user_registered)
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");
      });


    campaignData()
      .then((success) => {
        if (success.data.statusCode === 200) {
          success.data.campaign_registration.map((key) => {
            setCampaignNameData((prevUsers) => [...prevUsers, key.campaign_name]);
          });
          setUserData(success.data.campaign_registration);
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");

      });
    localStorage.setItem("smsCampaignNav", value)
  }, [value])

  const handleChange = (event, newValue) => {
    setFile(null);
    setError("");

    setCampaignAccount("")
    setCampaignpeid("")
    setCampaignheader("")
    setCampaigntemplate("")
    setCampaignmessage("")
    setCampaigntrackingUrl("")
    setCampaigncampaignName("")

    setTemplate("");
    setTemplateContentId("");
    setTemplateHeader("");
    setTemplateName("");
    setTemplatePeid("")

    setErrorAccount("")
    setErrorUserChain("")
    setErrorSmsc("")
    setErrorActual("")
    setErrorUpdatedCode("")
    setErrorUpdatedStatus("")
    setErrorDescription("")
    setTitle("");
    setUrl("");
    setGroupName("")
    setExportUser("")

    setValue(newValue);
    localStorage.setItem("smsCampaignNav", newValue)
  };


  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  // };

  //START
  const handleUrlChange = (event) => {
    setUrl(event.target.value)
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleGroupNameChange = (event) => {
    setGroupName(event.target.value)
  }

  const handleSubmit = () => {

    if (value === "url_shorten") {
      if (!title) {
        setError("Please enter title.");
        return;
      }

      if (!url) {
        setError("Please enter URL.");
        return;
      }

      const formData = {
        "user_id": "73",
        "title": title,
        "url": url
      }
      shortenURLAPI(formData)
        .then((success) => {
          console.log('HIT');
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setTitle("");
            setUrl("");
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
    else if (value === "address_book") {

      if (!groupName) {
        setError("Please enter group name.");
        return;
      }

      if (!file) {
        setError("Please select a file.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", "73");
      formData.append("groupname", groupName);

      addressBookAPI(formData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setFile(null);
            setGroupName("")
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
    else if (value === "err_mapping") {
      if (!errorAccount) {
        setError("Please enter Account.");
        return;
      }

      if (!errorUserChain) {
        setError("Please enter User Chain.");
        return;
      }

      if (!errorSmsc) {
        setError("Please enter SMSC code.");
        return;
      }

      if (!errorActual) {
        setError("Please enter actual error code.");
        return;
      }


      if (!errorUpdatedCode) {
        setError("Please enter Updated error code.");
        return;
      }

      if (!errorUpdatedStatus) {
        setError("Please enter Updated Status.");
        return;
      }
      if (!errorDescription) {
        setError("Please enter description.");
        return;
      }



      const formData = {
        "account": errorAccount,
        "user_chain": errorUserChain,
        "smsc": errorSmsc,
        "actual_err_code": errorActual,
        "updated_err_code": errorUpdatedCode,
        "updated_err_status": errorUpdatedStatus,
        "description": errorDescription
      }
      errorCodeMappingAPI(formData)
        .then((success) => {
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setErrorAccount("")
            setErrorUserChain("")
            setErrorSmsc("")
            setErrorActual("")
            setErrorUpdatedCode("")
            setErrorUpdatedStatus("")
            setErrorDescription("")

            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });

    }
    else if (value === "template_registration") {

      if (regType === "manual") {
        if (!templateName) {
          setError("Please enter Template Name.");
          return;
        }

        if (!templateHeader) {
          setError("Please enter Header.");
          return;
        }

        if (!templatePeid) {
          setError("Please enter PE ID.");
          return;
        }

        if (!templateContentId) {
          setError("Please enter Content ID.");
          return;
        }
        if (!template) {
          setError("Please enter Template Content.");
          return;
        }


        const formData = {
          "template_name": templateName,
          "header": templateHeader,
          "peid": templatePeid,
          "content_id": templateContentId,
          "template": template,
          "user_id": "73"
        }
        templateRegManualAPI(formData)
          .then((success) => {
            if (success.data.statusCode === 200) {
              setMessage(success.data.message)
              setSuccessSB(true);

              setTemplate("");
              setTemplateContentId("");
              setTemplateHeader("");
              setTemplateName("");
              setTemplatePeid("")


              setError("");
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            } else if (success?.error?.data?.statusCode > 300) {
              setError("Error in template mapping.");
            }
          })
          .catch((error) => {
            setError("An error occurred.");
          });
      }
      else {
        if (!file) {
          setError("Please select a file.");
          return;
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("user_id", "73");
        templateBulkRegAPI(formData)
          .then((success) => {
            if (success.data.statusCode === 200) {
              setMessage(success.data.message)
              setSuccessSB(true);
              setFile(null)

              setError("");
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            } else if (success?.error?.data?.statusCode > 300) {
              setError("Error in template mapping.");
            }
          })
          .catch((error) => {
            setError("An error occurred.");
          });
      }

    }
    else if (value === "campaign_registration") {
      // if (!campaignUserId) {
      //   setError("Please enter User ID.");
      //   return;
      // }

      if (!campaignAccount) {
        setError("Please enter Account Name.");
        return;
      }

      if (!campaignpeid) {
        setError("Please enter PEID.");
        return;
      }

      if (!campaignheader) {
        setError("Please enter header.");
        return;
      }
      if (!campaigntemplate) {
        setError("Please enter Template.");
        return;
      }
      if (!campaignmessage) {
        setError("Please enter message.");
        return;
      }

      if (!campaigntrackingUrl) {
        setError("Please enter tracking URL.");
        return;
      }
      if (!campaigncampaignName) {
        setError("Please enter Campaign Name.");
        return;
      }


      const formData = {
        "user_id": "73",
        "account": campaignAccount,
        "peid": campaignpeid,
        "header": campaignheader,
        "template": campaigntemplate,
        "message": campaignmessage,
        "tracking_url": campaigntrackingUrl,
        "campaign_name": campaigncampaignName
      }
      campaignRegAPI(formData)
        .then((success) => {
          console.log('hit ', success);
          if (success.data.statusCode === 200) {
            console.log('hit2 ', success);

            setMessage(success.data.message)
            setSuccessSB(true);

            setCampaignAccount("")
            setCampaignpeid("")
            setCampaignheader("")
            setCampaigntemplate("")
            setCampaignmessage("")
            setCampaigntrackingUrl("")
            setCampaigncampaignName("")

            setError("");

          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
    else if (value === "compose_campaign") {
      if (!campaignName) {
        setError("Please select campaign name.");
        return;
      }

      if (!receiver) {
        setError("Please select receiver.");
        return;
      }

      const storeData = JSON.parse(localStorage.getItem("userData"));
      console.log('aaaaaaaaaaaaaa', storeData.id);
      const formData = {
        user_id: storeData.id,
        user_chain: storeData.user_chain,
        type: receiver,
        peid: selectedCampaignData.peid,
        header: selectedCampaignData.header,
        content_id: selectedCampaignData.content_id,
        original_url: originalUrl,
        tracking_url: trackingUrl,
        tracking_url_key: trackingUrlKey,
        account: selectedCampaignData.account,
        message: selectedCampaignData.message,
        campaign_name: selectedCampaignData.campaign_name,
        contact_group_id: selectedCampaignData.id,
        schedule_date: scheduleDate,
        schedule_time: scheduleTime,
        short_url_status: '0',
        http_sms_status: '0',
      }
      shortenURLAPI(formData)
        .then((success) => {
          console.log('HIT');
          if (success.data.statusCode === 200) {
            setMessage(success.data.message)
            setSuccessSB(true);
            setTitle("");
            setUrl("");
            setError("");
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");
        });
    }
  }

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Registration"
      content={!message ? "Registered Successfully" : message}
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  const [selectedCampaignData, setSelectedCampaignData] = useState([]);

  const [originalUrl, setOriginalUrl] = useState('');
  const [trackingUrl, setTrackingUrl] = useState('');
  const [trackingUrlKey, setTrackingUrlKey] = useState('');


  const campaignNameSearch = (e) => {
    const selectedCampaignName = e.target.value;
    setCampaignName(selectedCampaignName);

    // Find corresponding campaign data
    const correspondingCampaignData = userData.find((row) => row.campaign_name === selectedCampaignName);

    if (correspondingCampaignData) {
      setSelectedCampaignData(correspondingCampaignData);
      const correspondingUrl = sRows.find((row) => row.userid === correspondingCampaignData.user_id);

      if (correspondingUrl) {
        setOriginalUrl(correspondingUrl.iurld)
        setTrackingUrl(correspondingUrl.id)
        setTrackingUrlKey(correspondingUrl.title)

        console.log('Full Row Data:', correspondingUrl.title);
      } else {
        setOriginalUrl("")
        setTrackingUrl("")
        setTrackingUrlKey("")
        console.log('No corresponding URL data found.');
      }
    } else {
      console.log('No matching campaign found.');
    }
  };


  const receiverData = ['TEST', 'QUICK', 'FILE', 'ADDRESS'];
  const [receiver, setReceiver] = useState('');

  const handleReceiverChange = (event) => {
    console.log('hhhhhhhhhhhhhhh', event.target.value);
    setReceiver(event.target.value);
  };

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toLocaleTimeString('it-IT').slice(0, 5);

  const [msisdn, setMsisdn] = useState('');
  const [scheduleDate, setScheduleDate] = useState(currentDate);
  const [scheduleTime, setScheduleTime] = useState(currentTime);
  const handleMsisdnChange = (event) => {
    setMsisdn(event.target.value);
  };

  const handleDateChange = (event) => {
    setScheduleDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setScheduleTime(event.target.value);
  };

  const handleDownloadSample = async () => {
    let url;
    let fileName;

    if (value === "compose_campaign") {
      url = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=compose_sms.xlsx`;
      fileName = 'compose_sms.xlsx';
    } else if (value === "address_book") {
      url = `${process.env.REACT_APP_API_BASE_URL}/sample_download?filename=addressbook.xlsx`;
      fileName = 'addressbook.xlsx';
    }

    console.log('HIAHSDKASKLDKL:::))))', url);

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          alert('No records found.');
        } else {
          alert(`Error: ${response.statusText}`);
        }
        return;
      }

      // Convert the response to a blob
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName; // Use the file name determined earlier
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);

    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred while exporting the data.');
    }
  };


  const [selectedFileName, setSelectedFileName] = useState([]);
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    const files = Array.from(event.target.files);
    setSelectedFileName(files.map(file => file.name));
  };


  const exportValue = async () => {
    let encodedValue = encodeURIComponent(value);
    const selectedUser = usersData.find((u) => u.username === exportUser);
    const encodedData = encodeURIComponent(selectedUser.id);

    let fileName;

    switch (value) {
      case 'err_mapping':
        fileName = 'err_mapping.csv';
        break;
      case 'address_book':
        fileName = 'address_book.csv';
        break;
      case 'url_shorten':
        fileName = 'url_shorten.csv';
        break;
      case 'template_registration':
        fileName = 'template_registration.csv';
        break;
      case 'campaign_registration':
        fileName = 'campaign_registration.csv';
        break;
      case 'compose_campaign':
        fileName = 'compose_campaign.csv';
        break;
      default:
        console.error('Unknown value:', value);
        return;
    }

    const url = `${process.env.REACT_APP_API_BASE_URL}/get_campaign_details_export/${encodedValue}?user_id=${encodedData}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          // Handle "No records found" error
          alert('No records found.');
        } else {
          // Handle other possible errors
          alert(`Error: ${response.statusText}`);
        }
        return;
      }

      // If the request is successful, trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred while exporting the data.');
    }
  };
  return (
    <DashboardLayout>
      {renderSuccessSB}
      <DashboardNavbar />
      <Box pt={3} pb={3}>
        <TabContext value={value} sx={{ backgroundColor: "white" }}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Shorten URL" value="url_shorten" />
              <Tab label="Address Book" value="address_book" />
              <Tab label="Error Code Mapping" value="err_mapping" />
              <Tab label="Template Registration" value="template_registration" />
              <Tab label="Campaign Registration" value="campaign_registration" />
              <Tab label="Compose Campaign" value="compose_campaign" />
            </TabList>
          </Box>

          <TabPanel value="url_shorten">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Short URL
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration for Tracking!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">


                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Title"
                              variant="standard"
                              value={title}
                              onChange={handleTitleChange}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="URL"
                              variant="standard"
                              value={url}
                              onChange={handleUrlChange}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "124%", marginLeft: "-23%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Short URL Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>

                        <DataTable
                          table={{ columns: sColumns, rows: sRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="address_book">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Contacts
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration in Address Book!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Groupname"
                              variant="standard"
                              value={groupName}
                              onChange={handleGroupNameChange}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={2} mt={5}>
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={handleDownloadSample}
                              >
                                Download Sample
                              </MDButton>
                              <MDButton
                                variant="outlined"
                                color="info"
                                onClick={() => fileInputRef.current.click()}
                              >
                                Choose File
                              </MDButton>
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                              />
                            </Box>
                            <Box mt={2}>
                              {selectedFileName.length > 0 && (
                                <Typography variant="body2">
                                  {selectedFileName.length === 1
                                    ? `Selected file: ${selectedFileName[0]}`
                                    : `Selected files: ${selectedFileName.join(', ')}`}
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "110%", marginLeft: "-9%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Address Book Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: aColumns, rows: aRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="err_mapping">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Error Code
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">


                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={errorAccount}
                              onChange={(e) => setErrorAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="User Chain"
                              variant="standard"
                              value={errorUserChain}
                              onChange={(e) => setErrorUserChain(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="SMSC"
                              variant="standard"
                              value={errorSmsc}
                              onChange={(e) => setErrorSmsc(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Actual Error Code"
                              variant="standard"
                              value={errorActual}
                              onChange={(e) => setErrorActual(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="updated-error-code-input"
                              label="Updated Error Code"
                              variant="standard"
                              value={errorUpdatedCode}
                              onChange={(e) => setErrorUpdatedCode(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="updated-error-status-input"
                              label="Updated Error Status"
                              variant="standard"
                              value={errorUpdatedStatus}
                              onChange={(e) => setErrorUpdatedStatus(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="description-input"
                              label="Description"
                              variant="standard"
                              value={errorDescription}
                              onChange={(e) => setErrorDescription(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>



                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "125%", marginLeft: "-24%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Error Code Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: eColumns, rows: eRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="template_registration">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Template
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration for SMS Campaign!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Registered Via</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={regType}
                              onChange={(e) => setRegType(e.target.value)}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="file">File</MenuItem>
                              <MenuItem value="manual">Manual</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox>

                        {
                          (regType === "file") && (<MDBox mb={2} mt={5}>
                            <Grid item xs={12} >
                              <input type="file" ref={fileInputRef} onChange={handleFileChange} />
                            </Grid>
                          </MDBox>)
                        }
                        {
                          (regType === "manual") && (
                            <>
                              <MDBox mb={2}>
                                <FormControl variant="standard" fullWidth>
                                  <TextField
                                    id="template-name-input"
                                    label="Template Name"
                                    variant="standard"
                                    value={templateName}
                                    onChange={(e) => setTemplateName(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{
                                      style: {
                                        fontSize: '14px', // Adjust font size if needed
                                        lineHeight: '1.5', // Adjust line height if needed
                                      },
                                    }}
                                  />
                                </FormControl>
                              </MDBox>
                              <MDBox mb={2}>
                                <FormControl variant="standard" fullWidth>
                                  <TextField
                                    id="user-chain-input"
                                    label="header"
                                    variant="standard"
                                    value={templateHeader}
                                    onChange={(e) => setTemplateHeader(e.target.value)}
                                    fullWidth
                                  />
                                </FormControl>
                              </MDBox>
                              <MDBox mb={2}>
                                <FormControl variant="standard" fullWidth>
                                  <TextField
                                    id="user-chain-input"
                                    label="PE ID"
                                    variant="standard"
                                    value={templatePeid}
                                    onChange={(e) => setTemplatePeid(e.target.value)}
                                    fullWidth
                                  />
                                </FormControl>
                              </MDBox>
                              <MDBox mb={2}>
                                <FormControl variant="standard" fullWidth>
                                  <TextField
                                    id="user-chain-input"
                                    label="Content ID"
                                    variant="standard"
                                    value={templateContentId}
                                    onChange={(e) => setTemplateContentId(e.target.value)}
                                    fullWidth
                                  />
                                </FormControl>
                              </MDBox>
                              <MDBox mb={2}>
                                <FormControl variant="standard" fullWidth>
                                  <TextField
                                    id="user-chain-input"
                                    label="Template"
                                    variant="standard"
                                    value={template}
                                    onChange={(e) => setTemplate(e.target.value)}
                                    fullWidth
                                    InputLabelProps={{
                                      style: {
                                        fontSize: '14px', // Adjust font size if needed
                                        lineHeight: '1.5', // Adjust line height if needed
                                      },
                                    }}
                                  />
                                </FormControl>
                              </MDBox>
                            </>
                          )
                        }
                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "121%", marginLeft: "-20%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Template Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: tColumns, rows: tRows }}
                          entriesPerPage={true}
                          isSorted={false}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="campaign_registration">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Campaign
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={campaignAccount}
                              onChange={(e) => setCampaignAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="PE ID"
                              variant="standard"
                              value={campaignpeid}
                              onChange={(e) => setCampaignpeid(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Header"
                              variant="standard"
                              value={campaignheader}
                              onChange={(e) => setCampaignheader(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Template"
                              variant="standard"
                              value={campaigntemplate}
                              onChange={(e) => setCampaigntemplate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Message"
                              variant="standard"
                              value={campaignmessage}
                              onChange={(e) => setCampaignmessage(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Tracking URL"
                              variant="standard"
                              value={campaigntrackingUrl}
                              onChange={(e) => setCampaigntrackingUrl(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Campaign Name"
                              variant="standard"
                              value={campaigncampaignName}
                              onChange={(e) => setCampaigncampaignName(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                style: {
                                  fontSize: '14px', // Adjust font size if needed
                                  lineHeight: '1.5', // Adjust line height if needed
                                },
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "124%", marginLeft: "-23%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Campaign Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: cColumns, rows: cRows }}
                          isSorted={false}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>
          <TabPanel value="compose_campaign">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card style={{ width: "72%" }}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Compose Campaign
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Submission!!!
                      </MDTypography>
                    </MDBox>


                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">CAMPAIGN NAME</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={campaignName}
                              onChange={campaignNameSearch}
                              label="User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {campaignNameData.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        {Object.entries(selectedCampaignData).map(([key, value], index) => (
                          <MDBox mb={2} key={index}>
                            <FormControl variant="standard" fullWidth>
                              <TextField
                                id={`${key}-input`}
                                label={key.replace(/_/g, ' ').toUpperCase()}
                                variant="standard"
                                value={value}
                                fullWidth
                              />
                            </FormControl>
                          </MDBox>
                        ))}
                        {/* Receiver Selection */}
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="demo-simple-select-standard-label">Select Receiver</InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={receiver}
                            onChange={handleReceiverChange}
                            label="Select Receiver"
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 200, // adjust the max height as needed
                                  overflow: 'auto',
                                },
                              },
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {receiverData.map((id, index) => (
                              <MenuItem key={index} value={id}>
                                {id}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        {/* Conditionally Rendered Fields */}
                        {(receiver === "TEST" || receiver === "QUICK") && (
                          <>
                            {/* MSISDN Input */}
                            <MDBox mb={2} mt={2}>
                              <FormControl variant="standard" fullWidth>
                                <TextField
                                  id="msisdn-input"
                                  label="MSISDNs"
                                  variant="standard"
                                  value={msisdn}
                                  onChange={handleMsisdnChange}
                                  fullWidth
                                />
                              </FormControl>
                            </MDBox>
                          </>
                        )}

                        {(receiver === "FILE") && (
                          <MDBox mb={2} mt={5}>
                            <Grid item xs={12}>
                              <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
                                <MDButton
                                  variant="outlined"
                                  color="info"
                                  onClick={handleDownloadSample}
                                >
                                  Download Sample
                                </MDButton>
                                <MDButton
                                  variant="outlined"
                                  color="info"
                                  onClick={() => fileInputRef.current.click()}
                                >
                                  Choose File
                                </MDButton>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  onChange={handleFileChange}
                                  multiple
                                  style={{ display: 'none' }}
                                />
                              </Box>
                              <Box mt={2}>
                                {selectedFileName.length > 0 && (
                                  <Typography variant="body2">
                                    {selectedFileName.length === 1
                                      ? `Selected file: ${selectedFileName[0]}`
                                      : `Selected files: ${selectedFileName.join(', ')}`}
                                  </Typography>
                                )}
                              </Box>
                            </Grid>
                          </MDBox>
                        )}
                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="Schedule Date"
                              type="date"
                              value={scheduleDate}
                              onChange={handleDateChange}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        {/* Schedule Time Selection */}
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-time"
                              label="Schedule Time"
                              type="time"
                              value={scheduleTime}
                              onChange={handleTimeChange}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>



                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "112%", marginLeft: "-10%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Campaign Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
                          <FormControl variant="standard" style={{ width: "20%" }}>
                            <InputLabel id="senderId-label">Select User</InputLabel>
                            <Select
                              labelId="senderId-label"
                              id="senderId"
                              value={exportUser}
                              onChange={(e) => setExportUser(e.target.value)}
                              label="Select User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users?.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          <MDButton
                            variant="gradient"
                            value="true"
                            color="dark"
                            onClick={exportValue}
                            sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                          >
                            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                            &nbsp;EXPORT
                          </MDButton>

                        </MDBox>
                        <DataTable
                          table={{ columns: ccColumns, rows: ccRows }}
                          isSorted={false}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>

        </TabContext>
      </Box>
      <Footer />
    </DashboardLayout >
  );
}

export default SmsCampaign;
