import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useGetRegisteredUserListMutation } from 'services/post';

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetRegisteredUserListMutation();

  useEffect(() => {
    // Fetch data from the API
    getuser()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          const fetchedData = data?.get_user_registered?.map((user) => ({
            
            id: user.id,
            name: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.name}
              </MDTypography>
            ),
            username: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.username}
              </MDTypography>
            ),
            email: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.email}
              </MDTypography>
            ),
            mobile: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.mobile}
              </MDTypography>
            ),
            account_validity: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {/* {user.account_validity} */}
                {new Date(user.account_validity).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(user.account_validity).getFullYear()}, {new Date(user.account_validity).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}

              </MDTypography>
            ),
            created_date: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {/* {user.created_date} */}
                {new Date(user.created_date).toLocaleDateString("en-US", { day: 'numeric', month: 'short' })} {new Date(user.created_date).getFullYear()}, {new Date(user.created_date).toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false })}
              </MDTypography>
            ),
            // action: (
            //   <MDBox>
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" mr={2}>
            //       Edit
            //     </MDTypography>
            //     <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //       Delete
            //     </MDTypography>
            //   </MDBox>
            // ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Username", accessor: "username", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Mobile", accessor: "mobile", align: "left" },
      { Header: "Account Validity", accessor: "account_validity", align: "left" },
      { Header: "Created Date", accessor: "created_date", align: "left" },
      // { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
