import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  AppBar,
  Tabs,
  Tab,
  Icon,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/user-management/dataUser";
import accountTableData from "layouts/user-management/dataAccount";

// Styles
import "./../../assets/styles/styles.css";
import breakpoints from "assets/theme/base/breakpoints";

const UserManagement = () => {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const { columns: userColumns, rows: userRows } = authorsTableData();
  const { columns: accountColumns, rows: accountRows } = accountTableData();

  const renderTable = () => {
    if (tabValue === 0) {
      return (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  className="arrow-icon"
                >
                  <MDTypography variant="h6" color="white">
                    Registered Users Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: userColumns, rows: userRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      );
    } else if (tabValue === 1) {
      return (
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  className="arrow-icon"
                >
                  <MDTypography variant="h6" color="white">
                    Registered Account Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: accountColumns, rows: accountRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
          <MDBox
            display="flex"
            alignItems="center"
            position="relative"
            minHeight="5rem"
            borderRadius="xl"
            sx={{
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          />
          <Card
            sm={6}
            sx={{
              position: "relative",
              mt: -8,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6} lg={4}>
                <AppBar position="static" sx={{ backgroundColor: "white", borderRadius: "8px" }}>
                  <Tabs
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={handleSetTabValue}
                    centered
                  >
                    <Tab
                      value={0}
                      label="Users"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          home
                        </Icon>
                      }
                    />
                    <Tab
                      value={1}
                      label="Accounts"
                      icon={
                        <Icon fontSize="small" sx={{ mt: -0.25 }}>
                          email
                        </Icon>
                      }
                    />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Card>
        </MDBox>
      {renderTable()}
      <Footer />
    </DashboardLayout>
  );
};

export default UserManagement;
