import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetAddressBookMutation } from 'services/post';
import { GetApp } from '@mui/icons-material';

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetAddressBookMutation();
  const handleDownload = async (id) => {
    console.log('URLLLL', id);
    const encodedData = encodeURIComponent(id);
    const url = `${process.env.REACT_APP_API_BASE_URL}/download_address_book?address_book=${encodedData}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          alert('No records found.');
        } else {
          alert(`Error: ${response.statusText}`);
        }
        return;
      }

      // If the request is successful, process the download
      const blob = await response.blob(); // Convert response to blob
      const downloadUrl = window.URL.createObjectURL(blob);

      const fileName = 'downloaded_file'; // Set a default file name or extract from response headers

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(link);

    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred while exporting the data.');
    }
  };


  useEffect(() => {

    getuser()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          const fetchedData = data.address_book_result.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            groupname: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.groupname}
              </MDTypography>
            ),
            count: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.count}
                <IconButton
                  aria-label="download"
                  onClick={() => handleDownload(item.id)}
                  sx={{ color: '#82E121' }}
                >
                  <GetApp fontSize="small" />
                </IconButton>

              </MDTypography>
            ),
            createdat: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.created_date ? new Date(item.created_date).toLocaleString('en-US', { timeZone: 'UTC' }) : "-"}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Group Name", accessor: "groupname", align: "left" },
      { Header: "Count", accessor: "count", align: "left" },
      { Header: "Created At", accessor: "createdat", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
