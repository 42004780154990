import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useHeaderSummaryMutation } from 'services/post';

const Data = ( postData ) => {  
  const [rows, setRows] = useState([]);
  const [headerSummary] = useHeaderSummaryMutation();
  useEffect(() => {
    headerSummary(postData)
      .unwrap()
      .then((response) => {
        if (response?.statusCode === 200) {
          const fetchedData = response.header_summary.map((item) => ({
            submitDate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.submit_date).toLocaleString()}
              </MDTypography>
            ),
            account: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account}
              </MDTypography>
            ),
            headerCode: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.header}
              </MDTypography>
            ),
            submitCount: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.submit_count}
              </MDTypography>
            ),
            delivery: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.delivery}
              </MDTypography>
            ),
            failed: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.failed}
              </MDTypography>
            ),
            pending: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.pending}
              </MDTypography>
            ),
            nack: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.nack}
              </MDTypography>
            ),
          }));
          setRows(fetchedData);
        } else if (response.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [headerSummary, postData]);

  return {
    columns: [
      { Header: "Submit Date", accessor: "submitDate", align: "left" },
      { Header: "Account", accessor: "account", align: "left" },
      { Header: "Header Code", accessor: "headerCode", align: "left" },
      { Header: "Submit Count", accessor: "submitCount", align: "left" },
      { Header: "Delivery", accessor: "delivery", align: "left" },
      { Header: "Failed", accessor: "failed", align: "left" },
      { Header: "Pending", accessor: "pending", align: "left" },
      { Header: "Nack", accessor: "nack", align: "left" },

    ],
    rows: rows,
  };
};

export default Data;
