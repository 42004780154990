import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useDlrRepushMutation, useRequestDownloadMutation } from "services/post";

import headerTableData from "layouts/summary/data/headerTableData";
import projectsTableData from "layouts/summary/data/projectsTableData";
import smscTableData from "layouts/summary/data/smscTableData";
import campaignTableData from "layouts/summary/data/campaignTableData";
import channelTableData from "layouts/summary/data/channelTableData";
import trafficTableData from "layouts/summary/data/trafficTableData";
import smsTableData from "layouts/summary/data/smsTableData";
import downloadTableData from "layouts/summary/data/downloadTableData"
import campaignTrackingTableData from "layouts/summary/data/campaignTrackingTableData"
import userTableData from "layouts/summary/data/userTableData"



import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox, Icon, TextField, useStepContext } from "@mui/material";
import Data from "./data/projectsTableData";
import { useErrorListMutation } from "services/post";
import { useGetRegisteredAccountsListMutation } from "services/post";
import { useGetRegisteredAccountNameMutation } from "services/post";
import { useHeaderListMutation } from "services/post";
import { useCampaignListMutation } from "services/post";
import { useSmscListMutation } from "services/post";


const Summary = () => {

  const [errorPostData, setErrorPostData] = useState({
    account: "",
    error: "",
    from_date: "",
    to_date: ""
  })
  const [headerPostData, setHeaderPostData] = useState({
    account: "",
    header: "",
    from_date: "",
    to_date: ""
  })
  const [smscPostData, setSmscPostData] = useState({
    account: "",
    smsc: "",
    from_date: "",
    to_date: ""
  })
  const [campaignPostData, setcampaignPostData] = useState({
    account: "",
    campaign: "",
    from_date: "",
    to_date: ""
  })
  const [downloadData, setDownloadData] = useState({
    user_id: "",
    account: "",
    date: "",
    todate: "",
    fromdate: "",
    created_by_user_chain: ""
  })
  const [postData, setPostData] = useState({
    account: "",
    header: "",
    startDate: "",
    endDate: ""
  })
  const { columns: hColumns, rows: hRows } = headerTableData(headerPostData);
  const { columns: pColumns, rows: pRows } = projectsTableData(errorPostData);
  const { columns: scColumns, rows: scRows } = smscTableData(smscPostData);
  const { columns: cColumns, rows: cRows } = campaignTableData(campaignPostData);

  const { columns: chColumns, rows: chRows } = channelTableData(postData);
  const { columns: tColumns, rows: tRows } = trafficTableData(postData);
  const { columns: sColumns, rows: sRows } = smsTableData(postData);
  const { columns: dColumns, rows: dRows } = downloadTableData(downloadData);
  const { columns: ctColumns, rows: ctRows } = campaignTrackingTableData(postData);
  const { columns: uColumns, rows: uRows } = userTableData(postData);






  const [value, setValue] = useState("1");
  const [age, setAge] = useState("");
  const [senderIds, setSenderIds] = useState([]);
  const [peIds, setPeIds] = useState("");
  const [file, setFile] = useState(null);
  const [downloadRequest] = useRequestDownloadMutation();
  const [getErrorList] = useErrorListMutation();
  const [getHeaderList] = useHeaderListMutation();
  // const [getSmscList] = useSmscListMutation();

  const [getCampaignList] = useCampaignListMutation();


  const [accountName] = useGetRegisteredAccountNameMutation();



  const [error, setError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState("")


  // START
  const [repushAccount, setRepushAccount] = useState("")
  const [repushToDate, setRepushToDate] = useState("")
  const [repushFromDate, setRepushFromDate] = useState("")
  const [addError, setAddError] = useState("")
  const [header, setHeader] = useState("")
  const [smsc, setSmsc] = useState("")
  const [campaign, setCampaign] = useState("")
  const [channel, setChannel] = useState("")
  const [traffic, setTraffic] = useState("")
  const [sms, setSms] = useState("")
  const [date, setDate] = useState("")
  const [user, setUser] = useState("")
  const [userChain, setUserChain] = useState("")
  const [errorListDropdown, setErrorListDropdown] = useState([])
  const [headerListDropdown, setHeaderListDropdown] = useState([])
  const [smscListDropdown, setSmscListDropdown] = useState([])
  const [campaignListDropdown, setCampaignListDropdown] = useState([])


  const [account, setAccount] = useState([])





  useEffect(() => {
    getErrorList()
      .then((success) => {
        if (success.data.statusCode === 200) {
          setErrorListDropdown(success.data.error_list)
        } else if (success?.error?.data?.statusCode > 300) {
        }
      })
      .catch((error) => {
      });


    getHeaderList()
      .then((success) => {
        if (success.data.statusCode === 200) {
          setHeaderListDropdown(success.data.header_list)
        } else if (success?.error?.data?.statusCode > 300) {
        }
      })
      .catch((error) => {
      });

    // getSmscList()
    //   .then((success) => {y
    //     if (success.data.statusCode === 200) {
    //       setSmscListDropdown(success.data.smsc_list)
    //     } else if (success?.error?.data?.statusCode > 300) {
    //     }
    //   })
    //   .catch((error) => {
    //   });


    getCampaignList()
      .then((success) => {
        if (success.data.statusCode === 200) {
          setCampaignListDropdown(success.data.campaign_list)
        } else if (success?.error?.data?.statusCode > 300) {
        }
      })
      .catch((error) => {
      });

    getAccountList();
  }, [value])

  const getAccountList = () => {
    accountName("")
      .then((success) => {
        if (success.data.statusCode === 200) {
          success.data.get_registered_accounts_name.map((key) => {
            setAccount((prevUsers) => [...prevUsers, key.system_id]);
          });
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");

      });
  }


  const handleChange = (event, newValue) => {
    setFile(null);
    setError("");
    setRepushFromDate('');
    setRepushToDate('');
    setRepushAccount('');
    setPostData("");
    setAddError("");
    setHeader("");
    setSmsc("");
    setSms("");
    setCampaign("");
    setChannel("");
    setTraffic("");
    setValue(newValue);
  };

  const handleSubmit = async () => {

    if (value === "1") {
      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }
      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {
        const formData = {
          account: repushAccount,
          error: addError.error,
          from_date: repushFromDate,
          to_date: repushToDate,
        };


        console.log('FORM DATA', formData);
        setErrorPostData(formData);
        setError("")
      }
    }
    if (value === "2") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {
        const formData = {
          account: repushAccount,
          header: header.header,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setHeaderPostData(formData);
        setError("")
      }
    }
    if (value === "3") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          account: repushAccount,
          smsc: smsc.smsc,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setSmscPostData(formData);
        setError("")

      }
    }
    if (value === "4") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          account: repushAccount,
          campaign: campaign.campaign,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setcampaignPostData(formData);
        setError("")

      }
    }
    if (value === "5") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          account: repushAccount,
          channel: channel,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setPostData(formData);
        setError("")

      }
    }
    if (value === "6") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          account: repushAccount,
          sender: traffic,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setPostData(formData);
        setError("")

      }
    }
    if (value === "7") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          account: repushAccount,
          sender: sms,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setPostData(formData);
        setError("")

      }
    }

    if (value === "8") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          user_id: user,
          account: repushAccount,
          date: date,
          todate: repushToDate,
          fromdate: repushFromDate,
          created_by_user_chain: userChain
        };

        downloadRequest(formData)
          .then((success) => {
            if (success.data.statusCode === 200) {
              console.log('hittt', success);
              setMessage(success.data.message)
              setSuccessSB(true);

              setDownloadData(formData);
              setError("")
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            } else if (success?.error?.data?.statusCode > 300) {
              setError("Error in template mapping.");
            }
          })
          .catch((error) => {
            console.log('error', error);
            setError("An error occurred.");
          });
      }
    }
    if (value === "9") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }

      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {
        const formData = {
          account: repushAccount,
          campaign: campaign,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setPostData(formData);
        setError("")

      }
    }
    if (value === "10") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }

      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {
        const formData = {
          account: repushAccount,
          user: user,
          from_date: repushFromDate,
          to_date: repushToDate,
        };
        setPostData(formData);
        setError("")

      }
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Registration"
      content={message}
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );


  const exportValue = async () => {
    let encodedAccount = encodeURIComponent(repushAccount);
    let encodedFromDate = encodeURIComponent(repushFromDate);
    let encodedToDate = encodeURIComponent(repushToDate);

    let url;
    let fileName;
    switch (value) {
      case '1':
        let encodedError = encodeURIComponent(addError.error);
        fileName = 'error.csv';
        url = `${process.env.REACT_APP_API_BASE_URL}/error_summary?from_date=${encodedFromDate}&to_date=${encodedToDate}&account=${encodedAccount}&error=${encodedError}&export=true`;
        break;
      case '2':
        let encodedHeader = encodeURIComponent(header.header);
        fileName = 'header.csv';
        url = `${process.env.REACT_APP_API_BASE_URL}/header_summary?from_date=${encodedFromDate}&to_date=${encodedToDate}&account=${encodedAccount}&header=${encodedHeader}&export=true`;
        break;
      case '3':
        let encodedSmsc = encodeURIComponent(smsc.smsc);
        fileName = 'smsc.csv';
        url = `${process.env.REACT_APP_API_BASE_URL}/smsc_summary?from_date=${encodedFromDate}&to_date=${encodedToDate}&account=${encodedAccount}&smsc=${encodedSmsc}&export=true`;
        break;
      case '4':
        let encodedCampaign = encodeURIComponent(campaign.campaign);
        fileName = 'campaign.csv';
        url = `${process.env.REACT_APP_API_BASE_URL}/campaign_summary?from_date=${encodedFromDate}&to_date=${encodedToDate}&account=${encodedAccount}&campaign=${encodedCampaign}&export=true`;
        break;
      case '5':
        let encodedChannel = encodeURIComponent(channel);
        fileName = 'channel.csv';
        url = `${process.env.REACT_APP_API_BASE_URL}/channel_summary?from_date=${encodedFromDate}&to_date=${encodedToDate}&account=${encodedAccount}&channel=${encodedChannel}&export=true`;
        break;

      case '6':
        let encodedTraffic = encodeURIComponent(traffic);
        fileName = 'traffic.csv';
        url = `${process.env.REACT_APP_API_BASE_URL}/traffic_summary?from_date=${encodedFromDate}&to_date=${encodedToDate}&account=${encodedAccount}&sender=${encodedTraffic}&export=true`;
        break;
      case 'contentid':
        fileName = 'contentId.csv';
        break;
      case 'spam':
        fileName = 'spam.csv';
        break;
      default:
        console.error('Unknown value:', value);
        return;
    }


    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          // Handle "No records found" error
          alert('No records found.');
        } else {
          // Handle other possible errors
          alert(`Error: ${response.statusText}`);
        }
        return;
      }

      // If the request is successful, trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

    } catch (error) {
      console.error('An error occurred:', error);
      alert('An error occurred while exporting the data.');
    }
  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderSuccessSB}
      <Box pt={3} pb={3}>
        <TabContext value={value} sx={{ backgroundColor: "white" }}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Error List" value="1" />
              <Tab label="Header List" value="2" />
              <Tab label="SMSC List" value="3" />
              <Tab label="Campaign List" value="4" />
              <Tab label="Channel List" value="5" />
              <Tab label="Traffic List" value="6" />
              <Tab label="SMS List" value="7" />
              <Tab label="Download Center" value="8" />
              <Tab label="Campaign Tracking" value="9" />
              <Tab label="User List" value="10" />

            </TabList>
          </Box>

          <TabPanel value="1">
            <Card >
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card style={{ width: "90%" }}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Error List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>


                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Error List</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={addError}
                              onChange={(e) => setAddError(e.target.value)}
                              label="Error List"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {errorListDropdown.map((id, index) => (
                                <MenuItem key={index} value={id}>{id.error}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="To Date"
                              type="date"
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="From Date"
                              type="date"
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Search
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          ERROR LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: pColumns, rows: pRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>


          <TabPanel value="2">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card style={{ width: "90%" }}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Header List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Header List</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={header}
                              onChange={(e) => setHeader(e.target.value)}
                              label="Error List"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {headerListDropdown.map((id, index) => (
                                <MenuItem key={index} value={id}>{id.header}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="To Date"
                              type="date"
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="From Date"
                              type="date"
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Search
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          HEADER LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: hColumns, rows: hRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="3">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card style={{ width: "90%" }}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        SMSC List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">SMSC List</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={smsc}
                              onChange={(e) => setSmsc(e.target.value)}
                              label="Error List"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {smscListDropdown.map((id, index) => (
                                <MenuItem key={index} value={id}>{id.header}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="To Date"
                              type="date"
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="From Date"
                              type="date"
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Search
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          SMSC LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: scColumns, rows: scRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="4">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card style={{ width: "90%" }}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Campaign List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Campaign List</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={smsc}
                              onChange={(e) => setSmsc(e.target.value)}
                              label="Campaign Name"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {campaignListDropdown.map((id, index) => (
                                <MenuItem key={index} value={id}>{id.campaign}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="To Date"
                              type="date"
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2} mt={5}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="schedule-date"
                              label="From Date"
                              type="date"
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Search
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          SMSC LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: scColumns, rows: scRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="5">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Channel List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Channel"
                              variant="standard"
                              value={channel}
                              onChange={(e) => setChannel(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          CHANNEL LIST
                        </MDTypography>
                      </MDBox>

                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: chColumns, rows: chRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="6">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        SMSC Traffic List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Sender"
                              variant="standard"
                              value={traffic}
                              onChange={(e) => setTraffic(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          SMSC TRAFFIC LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: tColumns, rows: tRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="7">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        SMS List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="SMS"
                              variant="standard"
                              value={sms}
                              onChange={(e) => setSms(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          SMS LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: sColumns, rows: sRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="8">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Download Request
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Submission!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="User"
                              variant="standard"
                              value={user}
                              onChange={(e) => setUser(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="User Chain"
                              variant="standard"
                              value={userChain}
                              onChange={(e) => setUserChain(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          DOWNLOAD REQUEST LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: dColumns, rows: dRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="9">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Campaign Tracking List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Campaign"
                              variant="standard"
                              value={campaign}
                              onChange={(e) => setCampaign(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card style={{width:"98%", marginLeft:"32px"}}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          CAMPAIGN TRACKING LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: ctColumns, rows: ctRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="10">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Users List
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Searching!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="User"
                              variant="standard"
                              value={user}
                              onChange={(e) => setUser(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          USERS LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2} mt={2}>
                        <MDButton
                          variant="gradient"
                          value="true"
                          color="dark"
                          onClick={exportValue}
                          sx={{ ml: 2, flexGrow: 0 }} // Adds margin to the left and prevents it from growing
                        >
                          <Icon sx={{ fontWeight: "bold" }}>download</Icon>
                          &nbsp;EXPORT
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <DataTable
                          table={{ columns: uColumns, rows: uRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

        </TabContext>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Summary;
