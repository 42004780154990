import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useRequestDownloadMutation, useRoutingGroupMutation, useResellerRoutingMutation } from "services/post";

import smscRegTableData from "layouts/telco/data/smscRegTableData";
import routingGroupTableData from "layouts/telco/data/routingGroupTableData";
import resellerRoutingTableData from "layouts/telco/data/resellerRoutingTableData";
// import headerTableData from "layouts/summary/data/headerTableData";
// import smscTableData from "layouts/summary/data/smscTableData";
// import campaignTableData from "layouts/summary/data/campaignTableData";
// import channelTableData from "layouts/summary/data/channelTableData";
// import trafficTableData from "layouts/summary/data/trafficTableData";
// import smsTableData from "layouts/summary/data/smsTableData";
// import downloadTableData from "layouts/summary/data/downloadTableData"
// import campaignTrackingTableData from "layouts/summary/data/campaignTrackingTableData"
// import userTableData from "layouts/summary/data/userTableData"



import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox, TextField } from "@mui/material";


const Telco = () => {

  const [postData, setPostData] = useState({
    user_id: "37",
    smsc_id: "",
    alt_charset: "DEFAULT",
    transceiver_mode: "TRX",
    host: "",
    port: "",
    smsc_username: "",
    smsc_password: "",
    system_type: "SMPP",
    source_addr_ton: 1,
    source_addr_npi: 1,
    dest_addr_ton: 1,
    dest_addr_npi: 1,
    throughput: 1,
    instances: 1
  })

  const { columns: scColumns, rows: scRows } = smscRegTableData(postData);
  const { columns: rgColumns, rows: rgRows } = routingGroupTableData(postData);
  const { columns: rrColumns, rows: rrRows } = resellerRoutingTableData(postData);
  // const { columns: scColumns, rows: scRows } = smscTableData(postData);
  // const { columns: cColumns, rows: cRows } = campaignTableData(postData);
  // const { columns: chColumns, rows: chRows } = channelTableData(postData);
  // const { columns: tColumns, rows: tRows } = trafficTableData(postData);
  // const { columns: sColumns, rows: sRows } = smsTableData(postData);
  // const { columns: dColumns, rows: dRows } = downloadTableData(downloadData);
  // const { columns: ctColumns, rows: ctRows } = campaignTrackingTableData(postData);
  // const { columns: uColumns, rows: uRows } = userTableData(postData);





  const [routingGroup] = useRoutingGroupMutation();
  const [resellerRouting] = useResellerRoutingMutation();

  const [value, setValue] = useState("1");
  const [age, setAge] = useState("");
  const [senderIds, setSenderIds] = useState([]);
  const [peIds, setPeIds] = useState("");
  const [file, setFile] = useState(null);
  const [downloadRequest] = useRequestDownloadMutation();
  const [error, setError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState("")


  // START
  const [repushAccount, setRepushAccount] = useState("")
  const [repushToDate, setRepushToDate] = useState("")
  const [repushFromDate, setRepushFromDate] = useState("")

  const [addError, setAddError] = useState("")
  const [header, setHeader] = useState("")
  const [smsc, setSmsc] = useState("")
  const [campaign, setCampaign] = useState("")
  const [channel, setChannel] = useState("")
  const [traffic, setTraffic] = useState("")
  const [sms, setSms] = useState("")
  const [date, setDate] = useState("")
  const [user, setUser] = useState("")
  const [userChain, setUserChain] = useState("")


  //STARTTTTTTTTTTTTTTTTTT
  const [instances, setInstances] = useState("1")
  const [smscId, setSmscId] = useState("")
  const [altCharSet, setAltCharSet] = useState("UTF-8")
  const [host, setHost] = useState("")
  const [port, setPort] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [systemType, setSystemType] = useState("SMPP")
  const [sourceTon, setSourceTon] = useState("1")
  const [sourceNpi, setSourceNpi] = useState("1")
  const [destTon, setDestTon] = useState("1")
  const [destNpi, setDestNpi] = useState("1")
  const [throughput, setThroughput] = useState("1")
  const [transceiverMode, setTransceiverMode] = useState("TRX")
  const [groupId, setGroupId] = useState("")
  const [routingType, setRoutingType] = useState("")
  const [tps, setTps] = useState("")
  const [identifier, setIdentifier] = useState("")
  const [priority, setPriority] = useState("")
  const [reseller, setReseller] = useState("")
  const [group, setGroup] = useState("")







  const handleChange = (event, newValue) => {
    setFile(null);
    setError("");
    setRepushFromDate('');
    setRepushToDate('');
    setRepushAccount('');
    setPostData("");
    setAddError("");
    setHeader("");
    setSmsc("");
    setSms("");
    setCampaign("");
    setChannel("");
    setTraffic("");
    setValue(newValue);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {

    if (value === "1") {


      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      else if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      else {


        const formData = {
          user_id: "37",
          smsc_id: smscId,
          alt_charset: altCharSet,
          transceiver_mode: transceiverMode,
          host: host,
          port: port,
          smsc_username: username,
          smsc_password: password,
          system_type: systemType,
          source_addr_ton: sourceTon,
          source_addr_npi: sourceNpi,
          dest_addr_ton: destTon,
          dest_addr_npi: destNpi,
          throughput: throughput,
          instances: instances
        };
        setPostData(formData);
        setError("")

      }
    }
    if (value === "2") {


      if (!groupId) {
        setError("Please enter the group ID.");
        return;
      }


      else if (!routingType) {
        setError("Please select Routing Type.");
        return;
      }


      else if (!tps) {
        setError("Please enter TPS.");
        return;
      }


      else if (!identifier) {
        setError("Please enter Identifier.");
        return;
      }

      else if (!priority) {
        setError("Please enter Priority.");
        return;
      }


      else if (!smscId) {
        setError("Please enter SMSC ID.");
        return;
      }

      else {


        const formData = {
          group_id: groupId,
          routing_type: routingType,
          tps: tps,
          identifier: identifier,
          priority: priority,
          smsc_id: smscId
        };

        routingGroup(formData)
          .then((success) => {
            if (success.data.statusCode === 200) {
              setMessage(success.data.message)
              setSuccessSB(true);
              setPostData(formData);
              setError("");
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            } else if (success?.error?.data?.statusCode > 300) {
              setError("Error in Routing Group Submission.");
            }
          })
          .catch((error) => {
            setError("An error occurred.");
          });

        setPostData(formData);
        setError("")

      }
    }

    if (value === "3") {


      if (!reseller) {
        setError("Please enter the Reseller Name.");
        return;
      }


      else if (!group) {
        setError("Please enter the Group Name.");
        return;
      }

      else {
        const formData = {
          user_id: group,
          route_id: reseller
        };

        resellerRouting(formData)
          .then((success) => {
            if (success.data.statusCode === 200) {
              setMessage(success.data.message)
              setSuccessSB(true);
              setPostData(formData);
              setError("");
              if (fileInputRef.current) {
                fileInputRef.current.value = "";
              }
            } else if (success?.error?.data?.statusCode > 300) {
              setError("Error in Reseller Routing Submission.");
            }
          })
          .catch((error) => {
            setError("An error occurred.");
          });

        setPostData(formData);
        setError("")

      }
    }
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Registration"
      content={message}
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderSuccessSB}
      <Box pt={3} pb={3}>
        <TabContext value={value} sx={{ backgroundColor: "white" }}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="SMSC Registration" value="1" />
              <Tab label="Routing Group List" value="2" />
              <Tab label="Reseller Routing List" value="3" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        SMSC
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Instances"
                              variant="standard"
                              value={instances}
                              onChange={(e) => setInstances(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="SMSC ID"
                              variant="standard"
                              value={smscId}
                              onChange={(e) => setSmscId(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="ALT-Charset"
                              variant="standard"
                              value={smscId}
                              onChange={(e) => setAltCharSet(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Host"
                              variant="standard"
                              value={host}
                              onChange={(e) => setHost(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Port"
                              variant="standard"
                              value={port}
                              onChange={(e) => setPort(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="SMSC Username"
                              variant="standard"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="SMSC Password"
                              variant="standard"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="System Type"
                              variant="standard"
                              value={systemType}
                              onChange={(e) => setSystemType(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Source-Addr-Ton"
                              variant="standard"
                              value={sourceTon}
                              onChange={(e) => setSourceTon(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Source-Addr-Npi"
                              variant="standard"
                              value={sourceNpi}
                              onChange={(e) => setSourceNpi(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Dest-Addr-Ton"
                              variant="standard"
                              value={destTon}
                              onChange={(e) => setDestTon(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Dest-Addr-Npi"
                              variant="standard"
                              value={destNpi}
                              onChange={(e) => setDestNpi(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Throughput"
                              variant="standard"
                              value={throughput}
                              onChange={(e) => setThroughput(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Transceiver Mode"
                              variant="standard"
                              value={transceiverMode}
                              onChange={(e) => setTransceiverMode(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>



                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card style={{width:"98%", marginLeft:"0%"}}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          SMSC LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns: scColumns, rows: scRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="2">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Routing Group
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Submission!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Group ID"
                              variant="standard"
                              value={groupId}
                              onChange={(e) => setGroupId(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Routing Type</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              // value={senderIds}
                              onChange={(e) => setRoutingType(e.target.value)}
                              // label="Routing Type"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              <MenuItem value="percentage">
                                <option>Percentage</option>
                              </MenuItem>
                              <MenuItem value="header">
                                <option>Header</option>
                              </MenuItem>
                              <MenuItem value="receiver">
                                <option>Receiver</option>
                              </MenuItem>

                            </Select>
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="TPS"
                              variant="standard"
                              value={tps}
                              onChange={(e) => setTps(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Identifier"
                              variant="standard"
                              value={identifier}
                              onChange={(e) => setIdentifier(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Priority"
                              variant="standard"
                              value={priority}
                              onChange={(e) => setPriority(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="SMSC ID"
                              variant="standard"
                              value={smscId}
                              onChange={(e) => setSmscId(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card style={{width:"100%", marginLeft:"0%"}}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          ROUTING GROUP LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns: rgColumns, rows: rgRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>

          <TabPanel value="3">
            <Card>
              <Grid container rowSpacing={1} mt={5} mb={5}>
                
                <Grid container columnSpacing={1} xs={12} md={6} xl={2} ml={5}  >
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Reseller Routing
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Submission!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Reseller"
                              variant="standard"
                              value={reseller}
                              onChange={(e) => setReseller(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Group"
                              variant="standard"
                              value={group}
                              onChange={(e) => setGroup(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container spacing={6} xl={10}>
                  <Grid item xs={12} mr={4}>
                    <Card style={{width:"100%", marginLeft:"0%"}}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          RESELLER ROUTING LIST
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns: rrColumns, rows: rrRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        // canSearch={true}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>

          </TabPanel>



        </TabContext>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Telco;
