import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useErrorSummaryMutation } from 'services/post';

const Data = ( postData ) => {  
  const [rows, setRows] = useState([]);
  const [errorSummary] = useErrorSummaryMutation();
  useEffect(() => {
    errorSummary(postData)
      .unwrap()
      .then((response) => {
        if (response?.statusCode === 200) {
          const fetchedData = response.error_summary.map((item) => ({
            submitDate: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {new Date(item.submit_date).toLocaleString()}
              </MDTypography>
            ),
            account: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account}
              </MDTypography>
            ),
            errorCode: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.error}
              </MDTypography>
            ),
            submitCount: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item?.submit_count}
              </MDTypography>
            ),
          }));
          setRows(fetchedData);
        } else if (response.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [errorSummary, postData]);

  return {
    columns: [
      { Header: "Submit Date", accessor: "submitDate", align: "left" },
      { Header: "Account", accessor: "account", align: "left" },
      { Header: "Error Code", accessor: "errorCode", align: "left" },
      { Header: "Submit Count", accessor: "submitCount", align: "left" },
    ],
    rows: rows,
  };
};

export default Data;
