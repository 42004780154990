import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useCheckBillingDetailMutation } from "services/post";
import Invoice from "layouts/billing/components/Invoice";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";

function Invoices() {
  const [billingDetails] = useCheckBillingDetailMutation();
  const [billingData, setBillingData] = useState([]);

  useEffect(() => {
    billingDetails()
      .then((success) => {
        if (success.data.statusCode === 200) {
          const { billing_cycle, billing_type } = success.data.billing_details;
          const mergedData = billing_cycle.map((cycle, index) => ({
            ...cycle,
            typeValue: billing_type[index] ? billing_type[index].value : 'Unknown'
          }));
          setBillingData(mergedData);
          // toast.success("Successfully Submitted", { autoClose: 2000 });
        } else if (success?.error?.data?.statusCode > 300) {
          // toast.error(success?.error?.data?.message);
        }
      })
      .catch((error) => {
        // toast.error(error.message);
      });
  }, []);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Billing Details
        </MDTypography>
        <MDButton variant="outlined" color="info" size="small">
          view all
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {billingData.map((item) => (
            <Invoice
              key={item.id}
              date={item.value} // Billing cycle value
              id={`#${item.id}`}
              price={item.typeValue} // Billing type value
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Invoices;
