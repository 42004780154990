import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBlacklistDetailMutation } from 'services/post';

export default function Data(user) {
  const [rows, setRows] = useState([]);
  const [getuser] = useBlacklistDetailMutation();

  useEffect(() => {
    
    const userId = {
      user: user,
      id: "sender_id"
    }; // Replace with the dynamic userId you want to pass

    getuser(userId)
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          console.log("console:::---", data);
          const fetchedData = data.data.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            user_chain: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.user_chain}
              </MDTypography>
            ),
            account: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account}
              </MDTypography>
            ),
            text: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.keyword ? item.keyword : "-"}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser, user]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "User Chain", accessor: "user_chain", align: "left" },
      { Header: "Account", accessor: "account", align: "left" },
      { Header: "Text", accessor: "text", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
