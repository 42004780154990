import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useGetResellerRoutingMutation } from 'services/post';
import MDBox from 'components/MDBox';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Data = (postData) => {
  const [rows, setRows] = useState([]);
  const [resellerRoutingDetail] = useGetResellerRoutingMutation();
  useEffect(() => {
    resellerRoutingDetail()
      .unwrap()
      .then((response) => {
        if (response?.statusCode === 200) {
          const fetchedData = response.data.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            resellerName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.route_id}
              </MDTypography>
            ),
            groupName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.user_id}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (response.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [resellerRoutingDetail, postData]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Reseller Name", accessor: "resellerName", align: "left" },
      { Header: "Group Name", accessor: "groupName", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },

    ],
    rows: rows,
  };
};

export default Data;
