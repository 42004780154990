import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  Today_Submit: 1000000,
  Today_Deliver: 700000,
  Today_Failure: 300000,
  Today_Success_Percentage: '70',
  status: 'idle',
  error: null,
  data: {},
  columns_store: [
    { Header: "users", accessor: "users", width: "45%", align: "left" },
    { Header: "members", accessor: "members", width: "10%", align: "left" },
    { Header: "count submit", accessor: "totalcountsubmit", align: "center" },
    { Header: "count deliver", accessor: "completion", align: "center" },
  ],
  dailyData: {},
  dailyStatus: 'idle',
  dailyError: null,
  dailyPercentageData: {},
  dailyPercentageStatus: 'idle',
  dailyPercentageError: null,
  dailyAccountWiseData: {},
  dailyAccountWiseStatus: 'idle',
  dailyAccountWiseError: null,
  growthTrendData: {},
  growthTrendStatus: 'idle',
  growthTrendError: null,
  userType: {},
  userTypeStatus: 'idle',
  userTypeError: null,
};

// Define the async thunk with GET request for daily stats
export const fetchData = createAsyncThunk('mySlice/fetchData', async () => {
  const response = await axios.get('http://127.0.0.1:8080/daily_stats');
  return response.data;
});

// Define the async thunk with GET request for last 7 days stats
export const fetchDailyStats = createAsyncThunk('mySlice/fetchDailyStats', async () => {
  const response = await axios.get('http://127.0.0.1:8080/last_7days_stats');
  return response.data;
});

// Define the async thunk with GET request for last 7 days success percentage
export const fetchDailyPercentageStats = createAsyncThunk('mySlice/fetchDailyPercentageStats', async () => {
  const response = await axios.get('http://127.0.0.1:8080/last_7days_success_percentage');
  return response.data;
});

// Define the async thunk with GET request for account wise 7 days detail
export const fetchAccountWiseStats = createAsyncThunk('mySlice/fetchAccountWiseStats', async () => {
  const response = await axios.get('http://127.0.0.1:8080/account_wise_7days_detail');
  return response.data;
});

// Define the async thunk with GET request for last 6 month growth
export const fetchGrowthTrendStats = createAsyncThunk('mySlice/fetchGrowthTrendStats', async () => {
  const response = await axios.get('http://127.0.0.1:8080/last_6month_growth');
  return response.data;
});

// Define the async thunk with GET request for user type
export const getUserType = createAsyncThunk('mySlice/getUserType', async () => {
  const response = await axios.get('http://127.0.0.1:8080/get_user_type');
  return response.data;
});

// Define the async thunk with POST request to register user
export const registerUser = createAsyncThunk('mySlice/registerUser', async (userData) => {
  const response = await axios.post('http://127.0.0.1:8080/register_user', userData);
  return response.data;
});

const mySlice = createSlice({
  name: 'mySlice',
  initialState,
  reducers: {
    setValue: (state, action) => {
      state.myValue = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // FETCH DATA
      .addCase(fetchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // FETCH DAILY STATS
      .addCase(fetchDailyStats.pending, (state) => {
        state.dailyStatus = 'loading';
      })
      .addCase(fetchDailyStats.fulfilled, (state, action) => {
        state.dailyStatus = 'succeeded';
        state.dailyData = action.payload;
      })
      .addCase(fetchDailyStats.rejected, (state, action) => {
        state.dailyStatus = 'failed';
        state.dailyError = action.error.message;
      })

      // FETCH DAILY PERCENTAGE STATS
      .addCase(fetchDailyPercentageStats.pending, (state) => {
        state.dailyPercentageStatus = 'loading';
      })
      .addCase(fetchDailyPercentageStats.fulfilled, (state, action) => {
        state.dailyPercentageStatus = 'succeeded';
        state.dailyPercentageData = action.payload;
      })
      .addCase(fetchDailyPercentageStats.rejected, (state, action) => {
        state.dailyPercentageStatus = 'failed';
        state.dailyPercentageError = action.error.message;
      })

      // FETCH ACCOUNT WISE STATS
      .addCase(fetchAccountWiseStats.pending, (state) => {
        state.dailyAccountWiseStatus = 'loading';
      })
      .addCase(fetchAccountWiseStats.fulfilled, (state, action) => {
        state.dailyAccountWiseStatus = 'succeeded';
        state.dailyAccountWiseData = action.payload;
      })
      .addCase(fetchAccountWiseStats.rejected, (state, action) => {
        state.dailyAccountWiseStatus = 'failed';
        state.dailyAccountWiseError = action.error.message;
      })

      // FETCH 6 MONTH GROWTH STATS
      .addCase(fetchGrowthTrendStats.pending, (state) => {
        state.growthTrendStatus = 'loading';
      })
      .addCase(fetchGrowthTrendStats.fulfilled, (state, action) => {
        state.growthTrendStatus = 'succeeded';
        state.growthTrendData = action.payload;
      })
      .addCase(fetchGrowthTrendStats.rejected, (state, action) => {
        state.growthTrendStatus = 'failed';
        state.growthTrendError = action.error.message;
      })

      // REGISTER USER
      .addCase(registerUser.pending, (state) => {
        state.registerStatus = 'loading';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.registerStatus = 'succeeded';
        // Optionally, update state with the registered user data if needed
        // state.userData = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registerStatus = 'failed';
        state.registerError = action.error.message;
      })

      // GET USER TYPE
      .addCase(getUserType.pending, (state) => {
        state.userTypeStatus = 'loading';
      })
      .addCase(getUserType.fulfilled, (state, action) => {
        state.userTypeStatus = 'succeeded';
        state.userType = action.payload;
      })
      .addCase(getUserType.rejected, (state, action) => {
        state.userTypeStatus = 'failed';
        state.userTypeError = action.error.message;
      });
  },
});

export const { setValue } = mySlice.actions;
export default mySlice.reducer;
