import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetErrorCodeMappingMutation } from 'services/post';

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getuser] = useGetErrorCodeMappingMutation();

  useEffect(() => {

    getuser()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          console.log("console:::---", data);
          const fetchedData = data.err_code_mapping_result.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            account: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.account}
              </MDTypography>
            ),
            smsc: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.smsc}
              </MDTypography>
            ),

            originalcode: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.actual_err_code ? item.actual_err_code : "-"}
              </MDTypography>
            ),
            replacecode: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.updated_err_code ? item.updated_err_code : "-"}
              </MDTypography>
            ),
            description: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.description ? item.description : "-"}
              </MDTypography>
            ),
            replacestatus: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.updated_err_status ? item.updated_err_status : "-"}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getuser]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Account", accessor: "account", align: "left" },
      { Header: "SMSC", accessor: "smsc", align: "left" },
      { Header: "Original Code", accessor: "originalcode", align: "left" },
      { Header: "Replace Code", accessor: "replacecode", align: "center" },
      { Header: "Description", accessor: "description", align: "center" },
      { Header: "Replace Status", accessor: "replacestatus", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },

    ],
    rows: rows,
  };
}
