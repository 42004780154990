import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import { useGetRoutingGroupMutation } from 'services/post';
import MDBox from 'components/MDBox';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const Data = (postData) => {
  const [rows, setRows] = useState([]);
  const [routingGroupDetail] = useGetRoutingGroupMutation();
  useEffect(() => {
    routingGroupDetail()
      .unwrap()
      .then((response) => {
        if (response?.statusCode === 200) {
          const fetchedData = response.data.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            groupId: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.group_id}
              </MDTypography>
            ),
            routingType: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.routing_type}
              </MDTypography>
            ),
            tps: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.tps}
              </MDTypography>
            ),
            identifier: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.identifier}
              </MDTypography>
            ),
            priority: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.priority}
              </MDTypography>
            ),
            smscId: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.smsc_id}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (response.statusCode > 300) {
          console.log('Error status code > 300:', response?.message);
        }
      })
      .catch((error) => {
        console.log('Error occurred during fetch:', error);
      });
  }, [routingGroupDetail, postData]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Group ID", accessor: "groupId", align: "left" },
      { Header: "Routing Type", accessor: "routingType", align: "left" },
      { Header: "TPS", accessor: "tps", align: "left" },
      { Header: "Identifier", accessor: "identifier", align: "left" },
      { Header: "Priority", accessor: "priority", align: "left" },
      { Header: "SMSC ID", accessor: "smscId", align: "left" },
      { Header: "Action", accessor: "action", align: "left" },

    ],
    rows: rows,
  };
};

export default Data;
