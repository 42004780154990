import React, { useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox"; // Assuming MDBox is imported from somewhere
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetShortenUrlMutation } from 'services/post';

export default function Data() {
  const [rows, setRows] = useState([]);
  const [getShortenUrl] = useGetShortenUrlMutation();

  useEffect(() => {
    // 
    // const userId = "sender_id"; // Replace with the dynamic userId you want to pass

    getShortenUrl()
      .then((response) => {
        const { data, error } = response;
        if (data?.statusCode === 200) {
          console.log("console:::---", data);
          const fetchedData = data.url_shorten_result.map((item) => ({
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.id}
              </MDTypography>
            ),
            userid: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.user_id}
              </MDTypography>
            ),
            title: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.title}
              </MDTypography>
            ),
            url: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {item.url ? item.url : "-"}
              </MDTypography>
            ),
            action: (
              <MDBox>
                <IconButton aria-label="delete" sx={{ color: '#DE3D34' }}>
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            ),
          }));
          setRows(fetchedData);
        } else if (error?.data?.statusCode > 300) {
          alert(error.data.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  }, [getShortenUrl]);

  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "User ID", accessor: "userid", align: "left" },
      { Header: "title", accessor: "title", align: "left" },
      { Header: "url", accessor: "url", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows: rows,
  };
}
