import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useDlrRepushMutation, useMapTemplateSenderIdMutation, useGetRegisteredAccountsListMutation, useGetRegisteredAccountNameMutation } from "services/post";
import templateMappingData from "layouts/dlr/data/templateMappingData";
import projectsTableData from "layouts/dlr/data/projectsTableData";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { Checkbox, TextField } from "@mui/material";


function Dlr() {
  const { columns: tColumns, rows: tRows } = templateMappingData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [value, setValue] = useState("1");
  const [age, setAge] = useState("");
  const [senderIds, setSenderIds] = useState([]);
  const [peIds, setPeIds] = useState("");
  const [file, setFile] = useState(null);
  const [dlrRepushAPI] = useDlrRepushMutation();
  const [mapTemplateSenderId] = useMapTemplateSenderIdMutation();
  const [error, setError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const fileInputRef = useRef(null);
  const [postData, setPostData] = useState("")



  // START
  const [repushAccount, setRepushAccount] = useState("")
  const [repushToDate, setRepushToDate] = useState("")
  const [repushFromDate, setRepushFromDate] = useState("")
  const [accountName] = useGetRegisteredAccountNameMutation();
  const [account, setAccount] = useState([])
  const [users, setUsers] = useState([])
  const [userData, setUserData] = useState([])
  const [accounts, setAccounts] = useState([])
  const [registeredAccountsList] = useGetRegisteredAccountsListMutation();



  const handleChange = (event, newValue) => {
    setFile(null);
    setPeIds("")
    setError("");
    setAge("")
    setSenderIds("")
    setRepushFromDate('');
    setRepushToDate('');
    setRepushAccount('');
    setValue(newValue);
    setAccount("");
  };

  useEffect(() => {
    localStorage.setItem("blacklistNav", value)
    setPostData("")
    registeredAccountsList("")
      .then((success) => {
        console.log('HIT BEFORE:::::::::', success.data.get_registered_accounts
        );
        if (success.data.statusCode === 200) {
          success.data.get_registered_accounts.map((key) => {
            setUsers((prevUsers) => [...prevUsers, key.parent]);
          });
          setUserData(success.data.get_registered_accounts);
        } else if (success?.error?.data?.statusCode > 300) {
          setError("Error in template mapping.");
        }
      })
      .catch((error) => {
        setError("An error occurred.");

      });
  }, [value])

  const handleSenderIdsChange = (event) => {
    const selectedSenderId = event.target.value;
    setSenderIds(event.target.value);
    setPostData(selectedSenderId)
    if (selectedSenderId !== "") {
      accountName(selectedSenderId)
        .then((success) => {
          if (success.data.statusCode === 200) {
            success.data.get_registered_accounts_name.map((key) => {
              setAccount((prevUsers) => [...prevUsers, key.system_id]);
            });
          } else if (success?.error?.data?.statusCode > 300) {
            setError("Error in template mapping.");
          }
        })
        .catch((error) => {
          setError("An error occurred.");

        });
    }
    else {
      setAccount([])
    }

  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (value === "1") {
      if (!repushAccount) {
        setError("Please enter account ID .");
        return;
      }

      if (!repushToDate) {
        setError("Please select to Date.");
        return;
      }


      if (!repushFromDate) {
        setError("Please select to Date.");
        return;
      }
      const formData = {
        "account": repushAccount,
        "from_date": repushFromDate,
        "to_date": repushToDate
      }

      try {
        const result = await dlrRepushAPI(formData).unwrap();
        setSuccessSB(true);
        setSenderIds("")
        setAccount("")
        // Reset form fields after successful submission
        setRepushFromDate('');
        setRepushToDate('');
        setRepushAccount('');
        set
        setError('');
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle error (if needed)

      }
    }
    // else if (value === "2") {
    //   if (!senderIds) {
    //     setError("Please select Sender IDs.");
    //     return;
    //   }

    //   if (!peIds) {
    //     setError("Please select PE IDs.");
    //     return;
    //   }
    //   if (!file) {
    //     setError("Please select a file.");
    //     return;
    //   }

    //   const formData = new FormData();
    //   formData.append("user_id", "73");
    //   formData.append(
    //     "sender_ids",
    //     JSON.stringify([
    //       { sender_id: senderIds, peid: peIds }
    //     ])
    //   );
    //   formData.append("file", file);

    //   try {
    //     const result = await mapTemplateSenderId(formData).unwrap();
    //     setSuccessSB(true);

    //     // Reset form fields after successful submission
    //     setSuccessSB(true);
    //     setFile(null);
    //     setPeIds("")
    //     setError("");
    //     setSenderIds("")
    //     if (fileInputRef.current) {
    //       fileInputRef.current.value = '';
    //     }
    //   } catch (error) {
    //     console.error('Error:', error);
    //     // Handle error (if needed)

    //   }
    // }
  };

  // Extract sender IDs from projectsTableData
  // const senderIDs = [...new Set(pRows.map((row) => row.sender_id))];
  // const senderIDs = pRows.map((row) => {
  //   // Assuming sender_id is a React element like <Typography variant="caption">jiko</Typography>
  //   const children = row.sender_id.props.children;
  //   return children;
  // });
  // console.log('senderIDs::', senderIDs);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Registration"
      content="Operation completed successfully"
      dateTime="2 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      {renderSuccessSB}
      <DashboardNavbar />
      <Box pt={3} pb={3}>
        <TabContext value={value} sx={{ backgroundColor: "white" }}>
          <Box>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="DLR Repush" value="1" />
              <Tab label="DLR Webhook" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1} >
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5} >
                  <Card style={{width:"50%"}}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        DLR Repush
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Submission!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        {/* <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="user-chain-input"
                              label="Account"
                              variant="standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox> */}

                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">User Chain</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={senderIds}
                              onChange={handleSenderIdsChange}
                              label="User"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                              style={{ width: '100%' }} // Increase width here
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {users.map((id, index) => (
                                <MenuItem key={index} value={id}>{id}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2} mt={5}>
                          {/* <MDInput type="text" label="Name" variant="standard" fullWidth /> */}
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Account</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={repushAccount}
                              onChange={(e) => setRepushAccount(e.target.value)}
                              label="Account"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // adjust the max height as needed
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <option>None</option>
                              </MenuItem>
                              {/* <MenuItem value={account}>{account}</MenuItem> */}

                              {account && account.length > 0 ? (
                                account.map((id, index) => (
                                  <MenuItem key={index} value={id}>
                                    {id}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>
                                  <em>No Account Available</em>
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>

                        </MDBox>


                        <MDBox mb={4} mt={4}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="To Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushToDate}
                              onChange={(e) => setRepushToDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <TextField
                              id="campaign-date-input"
                              label="From Date"
                              type="date"
                              variant="standard"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={repushFromDate}
                              onChange={(e) => setRepushFromDate(e.target.value)}
                              fullWidth
                            />
                          </FormControl>
                        </MDBox>


                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}

                        </MDBox>

                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>


                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{ width: "124%", marginLeft: "-23%" }}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          DLR Repush Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns: pColumns, rows: pRows }}
                          isSorted={true}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel>

          {/* <TabPanel value="2">
            <Card sx={{ p: "2.5%" }}>
              <Grid container rowSpacing={1}>
                <Grid container columnSpacing={1} xs={12} md={6} xl={4} mt={5}>
                  <Card>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                        Template Mapping Registration
                      </MDTypography>
                      <MDTypography display="block" variant="button" color="white" my={1}>
                        Registration!!!
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                      <MDBox component="form" role="form">
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="userId-label">User ID</InputLabel>
                            <Select
                              labelId="userId-label"
                              id="userId"
                              value="73"
                              disabled
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="73">73</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="senderIds-label">Sender IDs</InputLabel>
                            <Select
                              labelId="senderIds-label"
                              id="senderIds"
                              value={senderIds}
                              onChange={handleSenderIdsChange}
                              label="Sender IDs"
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {senderIDs.map((id, index) => (
                                <MenuItem key={index} value={id}>
                                  {id}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="peIds-label">PE IDs</InputLabel>
                            <Select
                              labelId="peIds-label"
                              id="peIds"
                              value={peIds}
                              disabled
                              label="PE IDs"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value={peIds}>{peIds}</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <FormControl variant="standard" fullWidth>
                            <InputLabel id="registeredBy-label">Registered By</InputLabel>
                            <Select
                              labelId="registeredBy-label"
                              id="registeredBy"
                              value="file"
                              disabled
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="file">File</MenuItem>
                              <MenuItem value="manual">Manual</MenuItem>
                            </Select>
                          </FormControl>
                        </MDBox>
                        <MDBox mb={2}>
                          <Grid item xs={12}>
                            <input type="file" ref={fileInputRef} onChange={handleFileChange} />
                          </Grid>
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1} mt={3}>
                          <Checkbox />
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                          >
                            &nbsp;&nbsp;I agree to the&nbsp;
                          </MDTypography>
                          <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                          >
                            Terms and Conditions
                          </MDTypography>
                        </MDBox>
                        <MDBox mt={4} mb={1}>
                          <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                            Submit
                          </MDButton>
                          {error && (
                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                              {error}
                            </Typography>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid container rowSpacing={1} xs={12} md={6} xl={8}>
                  <Grid xs={12} mt={6} sx={{ mx: "auto" }}>
                    <Card style={{width:"124%", marginLeft:"-23%"}}>
                      <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                      >
                        <MDTypography variant="h6" color="white">
                          Template Mapping Details
                        </MDTypography>
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{ columns: tColumns, rows: tRows }}
                          isSorted={false}
                          entriesPerPage={true}
                          showTotalEntries={true}
                          noEndBorder
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </TabPanel> */}

        </TabContext>
      </Box>
      <Footer />
    </DashboardLayout>
  );
}

export default Dlr;
